@import '../sass/mixins.scss';
@import '../sass/colors.scss';

button {
  position: relative;
  background-color: $button-background-color;
  margin: 0 1rem 0 0;
  padding: 10px 20px;
  border-radius: 2rem;
  text-transform: uppercase;
  font: 400 13.3333px Arial; //for Chrome on Mac
  color: $button-color;
  .icon {
    color: currentColor;
  }
  cursor: pointer;
  border: 0;
  &:hover, &:focus {
    background-color: $button-background-color-hover;
  }
  &:active {
    background-color: $button-background-color-active;
  }

  &:disabled {
    color: $button-disabled-color !important;
    background-color: $button-disabled-background-color !important;
    border: 1px solid $button-disabled-border-color !important;
    cursor: default !important;
    .icon {
      color: $button-disabled-color;
    }
  }

  &.with-icon {
    padding-left: 10px;
  }
  .icon {
    vertical-align: -.2rem;
    height: 1.2rem;
    width: 1.2rem;
    margin-right: .5rem;
    margin-left: .25rem;
  }

  &.primary {
    border: 0;
    background-color: $button-primary-background-color;
    color: $button-primary-color;
    &:hover, &:focus {
      background-color: $button-primary-background-color-hover;
    }
    &:active {
      background-color: $button-primary-background-color-active;
    }
    &.danger {
      background-color: $button-danger-color;
      &:hover, &:focus {
        background-color: $button-danger-background-color-hover;
      }
      &:active {
        background-color: $button-danger-background-color-active;
      }
    }
    .icon {
      color: $button-primary-color;
    }
  }

  &.tertiary {
    border: 0;
    background-color: transparent;
    color: $button-tertiary-color;
    .icon {
      color: currentColor;
    }
    &:hover, &:focus {
      background-color: $button-background-color;
    }
    &:active {
      background-color: $button-background-color-active;
    }
    &.danger {
      color: $button-danger-color;
    }
  }

  &.icon-button {
    border: 0;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: transparent;
    .icon {
      color: $icon-color;
    }
    &:hover, &:focus {
      .icon {
        color: $icon-color-hover;
      }
      background-color: $button-background-color;
      transition: background-color 0.3s;
    }

    &.danger {
      color: $icon-danger-color;
      &:hover, &:focus {
        color: $icon-danger-color-hover;
      }
    }

    .icon {
      @include md-icon-size(1.5rem);
      margin: 0;
    }

    &.primary {
      border: 0;
      background-color: $button-primary-background-color;
      color: $mdi-light-fill;
      &:hover, &:focus {
        background-color: lighten($button-primary-background-color-hover, 5);
      }
      &:active {
        background-color: lighten($button-primary-background-color, 7.5);
      }
      &.danger {
        background-color: $button-danger-color;
        &:hover, &:focus {
          background-color: $button-danger-background-color-hover;
        }
        &:active {
          background-color: $button-danger-background-color-active;
        }
      }
    }

  }

  .tooltip {
    opacity: 0;
    font-size: 0.7rem;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    width: 0; //for screen-reader
    height: 0; //for screen-reader
    overflow: hidden //for screen-reader
  }

}

.for-button {

  &.done-icon {
    color: $feedback-ok-icon-color;
    margin: 0.5rem 1.5rem;
    animation: fade-in 1s ease;
  }

  &.loader {
    width: 1.6rem;
    height: 1.6rem;
    border-width: .2rem;
    margin: 0.5rem 1.5rem;
  }

}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}