@import '../../sass/colors';

.samtykke {

  position: relative;
  margin: 0 1rem 1rem 0;
  border: 1px solid $border-table-color;
  padding: 1.3rem 1.6rem .6rem 1.6rem;
  border-radius: .5rem;
  max-width: 50rem;
  clear: both;

  @media screen and (max-width: 500px) {
    margin: .5rem 0 !important;
  }

  legend {
    float: left;
    width: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    max-width: 39rem;
    margin-bottom: 1rem;
    white-space: pre-wrap;
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  p {
    font-weight: normal;
    max-width: 39rem;
    white-space: pre-wrap;
  }

  label:first-child {
    margin-top: 1.2rem;
  }

  .error-message-in-place {
    margin-top: 1rem;
    margin-bottom: .5rem;
  }

  .loading {
    .laster-data-container {
      opacity: 1;
    }
  }

}