@import "../../sass/colors";
@import '../../sass/mixins';


.datoer {
  .icon {
    @include md-icon-size(1rem);
    vertical-align: text-top;
  }

  .dato-icon {
    margin-right: 0.4rem;
  }


}