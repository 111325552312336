@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';
@import '../../sass/less-fun-variables';

$mobileBreakPoint: 755px;

.kursliste {
  text-align: left;
  margin-top: 0.5rem;
  flex: 1 1 auto;
  &__kurs {
    margin-bottom: 1.5rem;
    &__preview {
      border: .05rem solid lighten($default-border-color,20);
      border-radius: 1rem;
      padding: 1rem;
      position: relative;
      .feedback-full {
        padding: .5rem 1rem;
        min-height: 0 !important;
        margin-top: .5rem;
        &__text__no-icon {
          margin: 0;
        }
      }
      h2 {
        margin: 0;
        font-weight: normal;
        font-size: 1.6rem;
        width: 40rem;
        @media screen and (max-width: 1000px){
          max-width: 55vw;
        }
        @media screen and (max-width: 750px){
          max-width: 85vw;
        }
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .info-element {
        color: darken($small-text-lightest-gray, 10);
        font-size: 1rem;
        margin-top: .25rem;
        .icon {
          vertical-align: -.15rem;
          color: lighten($small-text-lightest-gray, 10);
          @include md-icon-size(1rem);
          &:not(:first-child){
            margin: 0;
          }
        }
        .pris, .varighet {
          margin-top: .5rem;
        }
      }
      &__actions {
        margin-top: .75rem;
        @media screen and (max-width: $mobileBreakPoint){
          text-align: center;
        }
        @media screen and (max-width: 350px){
          button {
            font-size: .7rem;
          }
        }
        @media screen and (max-width: 700px){
          button {
            margin: 0 .5rem;
            padding: .5rem 1rem;
            svg {
              display: none;
            }
          }
        }
      }
      &__kategorier {
        @media screen and (max-width: 600px) {
          margin-top: 0;
        }
        &__kategori {
          background-color: $kurs-kategori-std-bg-color;
          color: $mdi-light-fill;
          display: inline-block;
          padding: .2rem .75rem;
          margin: .25rem 0 .25rem .5rem;
          border-radius: .3rem;
          font-size: .7rem;
          &.heltid {
            background-color: $kurs-kategori-std-heltid-color;
          }
          &.deltid {
            background-color: $kurs-kategori-std-deltid-color;
          }
          &.eksamen {
            background-color: $kurs-kategori-std-eksamen-color;
          }
          &.nettbasert {
            background-color: $kurs-kategori-std-nettbasert-color;
          }
        }
      }
      .highlight {
        background-color: #fffba7;
      }
    }
  }
}