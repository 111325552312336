@use "sass:math";
@import '../sass/colors';
@import '../sass/mixins';

$mobileBreakPoint: 500px;

.feedback-full {
  $icon-size: 3rem;
  $base-spacing: 1rem;
  display: flex;
  margin: 1rem 0;
  padding: $base-spacing;
  border-radius: 1rem;
  min-height: $icon-size + (2*$base-spacing);
  background-color: $feedback-background-color-default;

  @media screen and (max-width: $mobileBreakPoint){
    padding: math.div($base-spacing, 1.25);
  }

  &.no-icon {
    min-height: 4.25rem;
  }

  &__ok {
    background-color: $feedback-background-color-ok;
    border: 1px solid lighten($feedback-ok-icon-color, 20%);

    .icon {
      color: $feedback-ok-icon-color;
    }
  }

  &__warning {
    background-color: $feedback-background-color-warning;
    border: 1px solid lighten($feedback-warning-icon-color, 20%);

    .icon {
      color: $feedback-warning-icon-color;
    }
  }

  &__error {
    background-color: $feedback-background-color-error;

    .icon {
      color: $feedback-error-icon-color;
    }
  }

  &__info .icon {
    color: $feedback-info-icon-color;
  }

  &__icon {

    margin-right: $base-spacing;

    .icon {
      @include md-icon-size($icon-size);
    }

    @media screen and (max-width: $mobileBreakPoint){
      margin-right: math.div($base-spacing, 1.5);
      .icon {
        @include md-icon-size(math.div($icon-size, 1.7));
      }
    }

  }

  &__text {

    @media screen and (min-width: $mobileBreakPoint) {
      &__content {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    ul {
      padding-left: 1rem;
      margin: 1rem 0 0 0;

      li {
        margin: .5rem 1rem;
      }
    }

    h1, h2, h3, h4 {
      margin: 0 0 1rem 0;
    }
  }
}