@import '../sass/mixins';

.vedlegg {

  .file-icon {
    margin-right: .25rem;
  }

  .ingen-filer-icon {
    vertical-align: text-bottom;
    margin-right: .25rem;
    @include md-icon-size(1.1rem);
  }

  .link {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;
  }

}