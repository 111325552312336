@import '../../../sass/mixins';


.tilknyttede-dokumenter {

  .file-icon {
    margin-right: .25rem;
  }

  .ingen-filer-icon {
    vertical-align: text-bottom;
    margin-right: .25rem;
    @include md-icon-size(1.1rem);
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;

    .loader {
      vertical-align: 0;
      margin-left: 0;
    }
  }

  .loader {
    width: 1.6rem;
    height: 1.6rem;
    border-width: .2rem;
    margin-left: 1rem;
  }

  .tilknyttet-dokument {
    margin-right: 1.5rem;
    white-space: nowrap;
    border-radius: 1rem;


    .link {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 8rem;
    }

    .button {
      margin-left: .25rem;
      margin-right: 0;
      vertical-align: super;

      .icon {
        color: $fjern-tilknytningColor;
        @include md-icon-size(1.2rem);
      }

      &:hover {
        .icon {
          color: $fjern-tilknytningColor-hover;
        }
      }
    }

    .for-button {
      &.loader {
        width: 1.2rem;
        height: 1.2rem;
        border-width: .2rem;
        margin-left: 1rem;
        vertical-align: bottom;
      }

    }

  }

}
