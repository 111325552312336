.korr-advarsel {

  & > div:first-child {
    margin: 0 0 2rem 0;
  }

  &__korr-lenke {
    margin-top: 0.5rem;
  }

  .button-and-feedback-wrapper {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .hent-pa-nytt {
      margin-bottom: 0.5rem;
    }

    .done-icon, .loader {
      margin-left: 0.5rem;
    }
  }
}