$small-text-lightest-gray: #767676; /* på hvit bakgrunn*/
$small-text-light-gray: #696969; /* på lys blå bakgrunn*/
$small-text-lightest-blue: #3774D7; /* på hvit bakgrunn*/

$font-color: #444;
$header-text-color: $font-color;
$default-border-color: #707070;
$font-color-error: #441415;

$mdi-default-background: #888;
$mdi-light-fill: #fff;

$focus-outline-color: #0067C5;
$focus-outline-color-error: #ff3536;

$error-message-color: #ce4c11;
$error-input-border-color: #ce4c11;

$icon-color: #607D8B;
$icon-color-hover: darken($icon-color, 20%);
$icon-danger-color: #ac6757;
$icon-alert-color: #ffb818;
$icon-danger-color-hover: darken($icon-danger-color, 20%);
$icon-color-dark: #666;

$clickable-area-hover-background-color: #ebf5ff;

$button-color: #505050;
$button-background-color: #e8e8e8;
$button-background-color-hover: #f4f4f4;
$button-background-color-active: #e0e0e0;
$button-border-color: #ccc;

$button-primary-background-color: #498235;
$button-primary-background-color-hover: #56a548;
$button-primary-background-color-active: #366e35;

$button-tertiary-color: #0f6a88;
$button-primary-color: #fff;

$button-danger-color: #ce4c11;
$button-danger-background-color-hover: #f64e11;
$button-danger-background-color-active: #ba4c11;

$button-disabled-color: #727272;
$button-disabled-background-color: #f7f8fa;
$button-disabled-border-color: #d9dce1;

$link-color: #0f6a88;

$checkbox-border-color: $default-border-color;
$checkbox-background-color: #eee;
$checkbox-background-hover-color: #888888;
$checkbox-background-checked-color: #0067C5;

$feedback-background-color-default: #f2f2f2;
$feedback-background-color-warning: #fffaeb;
$feedback-background-color-error: #ffe9e4;
$feedback-background-color-ok: #dfffe1;
$feedback-ok-icon-color: #69966C;
$feedback-warning-icon-color: darken($icon-alert-color, 10%);
$feedback-error-icon-color: darken($button-danger-color, 10%);
$feedback-info-icon-color: $icon-color;

$pamelding-steg-nummer-inactive-background-color: #f5f4f1;
$pamelding-steg-nummer-inactive-border-color: #ddd;
$pamelding-steg-container-left-border-color: rgb(202, 206, 213);
$pamelding-steg-active-color: #087fac;
$pamelding-steg-error-color: #ce4c11;

$sporreskjema-valgfri-color: #3D8544;

$edit-mode-bg-color: #ebf5ff;

$kurs-kategori-std-bg-color: #767676;
$kurs-kategori-std-heltid-color: #e76958;
$kurs-kategori-std-deltid-color: #52cc7f;
$kurs-kategori-std-eksamen-color: #b56014;
$kurs-kategori-std-nettbasert-color: #2f7abd;

$status-text-color: #fff;
$status-background-color-red: #ce4c11;
$status-background-color-green: #447F2F;
$status-background-color-yellow: #fcff93;

$border-table-color: gainsboro;
$selected-row-background-color: #FAFAFA;
$row-hover-background-color: #f5f5f5;

$arbeidserfaringBgColor: rgb(245, 243, 254);
$arbeidserfaringBgColorDark: rgb(235, 233, 244);
$arbeidserfaringBgColorInactive: #fafafa;
$arbeidserfaringBgColorDarkInactive: #efefef;

$foretaksgodkjenningAvvisColor: #cd2d00;
$foretaksgodkjenningGodkjennColor: #008700;

$fjern-tilknytningColor: #b96953;
$fjern-tilknytningColor-hover: #c63e09;

$default-text-background-color: #f0f0f0;
$default-border-radius: 7px;
$default-border-color-light: #888888;