@keyframes rotationAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  display: inline-block;
  animation: rotationAnimation linear .7s;
  animation-iteration-count: infinite;
  height: 60px;
  width: 60px;
  border-top: 8px solid #ecf0f1;
  border-left: 8px solid #ecf0f1;
  border-bottom: 8px solid #097faf;
  border-right: 8px solid #097faf;
  border-radius: 50%;
  vertical-align: middle;
}