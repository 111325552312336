@import '../../sass/colors';
@import '../../sass/mixins';

$mobileWidth: 530px;
$max-width-dokumeter: 30rem;

.soknadsdokumenter {
  padding-right: .5rem;

  ul, ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }


  .dokumentasjon-kategori {
    padding-left: 0.5rem;
    margin-top: 5rem;
    max-width: 45rem;


    & > h4 {
      margin: 2rem 0 0.4rem 0;
      font-size: 1.1rem;
    }

    &:nth-child(2) {
      margin-top: 3rem;
    }

    &:last-child {
      margin-bottom: 3rem;
    }

    &.bare-andre-krav {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    .extra-info {
      margin-bottom: 1rem;
    }
  }

  .tilknyttede-dokumenter {
    margin: .6rem 0 .6rem 0;
  }

  .dropzone {
    max-width: $max-width-dokumeter;
    margin: .2rem 0;
  }

  .velg-fra-tidligere-opplastet-button {
    max-width: $max-width-dokumeter;
    width: 100%;
  }

  .tidligere-opplastet-boks {
    max-width: $max-width-dokumeter;
    border-top: 3px solid $border-table-color;
    border-bottom: 3px solid $border-table-color;
    overflow: hidden;
    animation: slide-in-tidligere-opplastet 1s ease-out;

    h4 {
      padding: 1rem;
      margin: 0;
      background-color: $default-text-background-color;
      border-bottom: 1px solid $border-table-color;
    }

    &__buttons {
      padding: .5rem 0;
      text-align: right;
      border-top: 1px solid $border-table-color;
      background-color:$default-text-background-color;

      .button.secondary {
        background-color: darken($button-background-color, 5%);
        &:hover {
          background-color: darken($button-background-color, 10%);
        }
      }
    }

  }


  .tidligere-opplastet {

    max-height: 20rem;
    overflow-y: scroll;

    &__dokument {
      position: relative;
      border-bottom: 1px solid $border-table-color;
      padding: 1rem 0;

      &.deleted {
        height: 0;
        opacity: 0;
        padding: 0;
        overflow: hidden;
        border-bottom-width: 0;
        transition: all 0.6s ease-in;
      }

    }

    &__filnavn {
      max-width: calc(100% - 9rem);
      margin-left: 4rem;
      word-break: break-all;

      a:focus {
        box-shadow: none;
        outline: 2px solid $focus-outline-color;
      }
    }

    &__dato {
      font-size: .8rem;
      margin-left: 4rem;
      margin-top: .5rem;
    }

    &__actions {
      position: absolute;
      right: 0;
      top: 1rem;
    }

    &__checkbox {
      position: absolute;
      left: 1rem;
      top: 1.5rem;
    }

  }

  .tidligere-brukt-arbeidserfaringer {
    max-height: 25rem;
    overflow-y: scroll;
  }

  .legg-til-button {
    button {
      font-size: .7rem;
      max-width: $max-width-dokumeter;
      width: 100%;

      &.secondary {
        background-color: inherit;
        border: 1px solid rgb(198, 195, 207);

        &:hover {
          background-color: $arbeidserfaringBgColorDark;
        }
      }
    }

  }


  @keyframes slide-in-tidligere-opplastet {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 1000px;
    }
  }

  .tilknyttede-arbeidserfaringer-wrapper {
    margin: .6rem 0 0.5rem 0;

    .tilknyttede-arbeidserfaringer {
      max-width: $max-width-dokumeter;

      &.hidden {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
      }
    }

    .briefcase-icon {
      vertical-align: -.25rem;
      margin-right: .35rem;
      @include md-icon-size(1.3rem);
    }

    .legg-til-endre-arbeidserfaring {
      margin-top: .6rem;
      max-width: 45rem;
    }
  }


}