@import '../../sass/less-fun-variables';

#change-lang {
  .dropdown__toggler__icon {
    margin-right: .5rem;
  }

  @media screen and (max-width: 480px) {
    .dropdown__toggler__icon {
      margin-right: 0;
    }
    .dropdown__toggler__shortcut {
      display: inline-block;
      font-weight: 600;
      font-size: 0.95rem;
    }

    .dropdown__toggler__text {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
    }

  }

  .chosen-language {
    white-space: nowrap;
    padding: 1rem 1.5rem;
    border-radius: .4rem;
    color: $navbarWrapperColor;
    font-weight: bold;
    margin: .13rem;

    .icon {
      color: $navbarWrapperColor;
      margin-right: .5rem;
      vertical-align: -.3rem;
    }
  }
}