@import "../../sass/colors";
@import '../../sass/mixins';

$mobileWidth: 680px;

$width-kurs-kolonne: calc(100% - 30rem);
$width-datoer-kolonne: 9rem;
$width-status-kolonne: 9rem;
$width-kursbevis-kolonne: 12rem;


.tidligere-kurs {

  ol {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  .kurs {
    width: $width-kurs-kolonne;
  }

  .datoer {
    width: $width-datoer-kolonne;
  }

  .status {
    width: $width-status-kolonne;
  }

  .kursbevis {
    width: $width-kursbevis-kolonne;

    button:hover {
      background-color: darken($button-background-color-hover, 10%);
    }
  }


  &__header {
    padding: 1rem 0 0.4rem 1rem;
    border-bottom: 1px solid gainsboro;
    color: $small-text-lightest-gray;
    font-size: 0.85rem;
    font-weight: 700;

    @media (max-width: $mobileWidth) {
      display: none;
    }

    & > * {
      display: inline-block;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    height: 5rem;
    padding-left: 0.8rem;

    &:not(:last-child) {
      border-bottom: 1px solid $border-table-color;
    }

    &:hover {
      background-color: $row-hover-background-color;
    }

    & > * {
      &:not(:last-child) {
        padding-right: 1rem;
      }
    }

    .status {
      .icon {
        color: $feedback-ok-icon-color;
        margin-right: 0.2rem;
      }

      & > span {
        vertical-align: super;
      }
    }

    @media (max-width: $mobileWidth) {

      display: block;
      height: auto;
      padding: 1rem 0.5rem;

      &:first-child {
        border-top: 1px solid $border-table-color;
      }

      .kurs, .datoer, .status, .kursbevis {
        display: block;
        width: auto;
      }

      .kurs {
        font-size: 1.5rem;
      }

      .datoer, .status, .kursbevis {
        padding-top: 1rem;
      }

    }


  }

  .datoer {
    .dato-icon {
      display: none;
    }
  }


}