@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$smallerAddressBreakPoint: 400px;

.kontaktinfo {

  .cards-container {
    max-width: 850px;
    align-items: flex-start;

    .flat-card {
      flex: 1 1 250px;
    }
  }

  .adresser .cards-container {
    justify-content: space-between;
  }

  .adresser .venstre {
    flex-grow: 1;
  }

  @media (min-width: 890px){
    .adresser .venstre {
      margin-right: 40px;
      width: 290px;
    }
  }

  .adresser .hoyre {
    flex-grow: 1;
  }

}

