@import "../sass/colors";

$mobileWidth: 530px;

.table {
  border-collapse: collapse;

  td {
    padding: .7rem 1rem;
    text-align: left;

    &.cell-icon {
      padding-left: 0;
      padding-right: 0;
    }
  }

  tbody tr {
    border-top: 0.1rem #e0e4ea solid;
  }

  th {
    font-weight: 600;
    text-align: left;
    padding: .5rem 1rem .75rem 1rem;
  }

  .column-header-with-sort {
    cursor: pointer;
    white-space: nowrap;

    .icon {
      width: 1rem;
      vertical-align: -6px;
      margin-left: 4px;

      &.invisible {
        display: none;
      }
    }
  }
}

@mixin mobil-tabell ($mobileWidth) {

  @media screen and (max-width: $mobileWidth) {
    width: 100%;

    thead {
      display: none;
    }

    tbody tr:first-child {
      border-top: none;
    }

    tbody tr > td {
      display: block;
      padding: .7rem 1rem;
      width: 100%;

      .edit {
        padding-left: 0;
      }

    }

    .mobil-heading {
      display: block;
    }
  }
}
