@import "../../sass/colors";
@import "../../sass/mixins";

.foretaksgodkjenning {

  margin-bottom: 2rem;

  h1 {
    margin: 1.5rem 0 !important;
    word-break: break-all;
  }

  h2 {
    font-size: 1.6rem;
  }

  .foretak-select {
    max-width: 25rem;
    margin-bottom: 2rem;
  }

  .kurs {
    margin-top: 1rem;

    &.collapsed {
      table, .feedback-full {
        display: none;
      }
    }

    &.extended {
      .toggle-kurs-button {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &:not(:last-child) {
      &:after {
        content: '';
        display: block;
        border-bottom: 1px solid $border-table-color;
        margin: 3rem 7.5rem;
      }
    }

    .toggle-kurs-button {
      padding: 0;
      margin: 0;
      background-color: transparent;
      display: block;
      border-radius: .5rem;
    }

    h3 {
      margin: 0;
      font-size: 1.15rem;
      text-align: left;

      .icon {
        vertical-align: -.5rem;
        margin: 0;
        transition: .25s ease-out;
        @include md-icon-size(2rem);
      }

      .nye-soknader-alert {
        display: inline-block;
        font-weight: bold;
        background-color: #f00;
        color: #fff;
        text-align: center;
        min-width: 1.5rem;
        min-height: 1.5rem;
        border-radius: 1rem;
        padding: .1rem .4rem 0 .4rem;
        margin-left: .5rem;
      }
    }

    p.detaljer {
      font-size: .9rem;
      margin: .25rem .25rem;

      & > span {
        margin: 0 .5rem;
        @media screen and (max-width: 500px) {
          margin-top: .25rem;
          display: block;
        }
      }
    }

    .besvarte-soknader-toggler {
      text-align: center;
      margin-top: 1rem;

      &.extended {
        .icon {
          transform: rotate(180deg);
        }
      }

      button {
        font-size: .7rem;
        padding: .25rem 1rem .25rem .25rem;

        &:hover, &:focus {
          background-color: lighten($button-background-color-hover, 2);
        }
      }
    }

  }
}

.pamelingslink {
  p {
    font-weight: bold;
    margin-top: 0;
    &.url {
      font-size: .8rem;
      font-weight: normal;
      word-break: break-all;
    }
  }
}