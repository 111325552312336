.dsf-advarsel {

  .button-and-feedback-wrapper {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .hent-pa-nytt {
      margin-bottom: 0.5rem;
    }

    .done-icon, .loader {
      margin-left: 0.5rem;
    }
  }
}