@import '../../sass/mixins.scss';
@import '../../sass/colors.scss';

.min-side {
  padding-bottom: 2rem;
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    .card {
      flex: 1 1 400px;
      &-header {
        color: $header-text-color;
        .icon {
          vertical-align: middle;
          background-color: $mdi-default-background;
          color: $mdi-light-fill;
          padding: 6.1px;
          border-radius: 50%;
          margin-right: 12px;
          @include md-icon-size(35px);
        }
      }
      &-content {
        .varsel {
          @include md-icon-size(30px);
          float: left;
          margin: -7px 15px 15px 0;
          color: darken($icon-alert-color, 17%);
        }
        &__text {
          & > span {
            display: block;
          }
        }
      }
      &-actions {
        button {
          text-transform: uppercase;
        }
      }
    }
  }

}