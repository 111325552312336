@import "../../sass/colors";

.gsk-vedtak {
  $gsk-background-color: #f8f8f8;

  &.with-list {
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5rem;
    background-color: $gsk-background-color;
    padding: 1rem 0 1rem .8rem;
    border-radius: 1rem;
  }


  .icon.bekreftet {
    color: $feedback-ok-icon-color;
    vertical-align: text-bottom;
    margin-right: .5rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-size: .95rem;
    border: 1px solid gainsboro;
    border-radius: 1rem;
    padding: .5rem;
    margin-right: 1rem;
    margin-top: .5rem;
    background-color: white;
  }


}
