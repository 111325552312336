@import "../../sass/colors";
@import '../../sass/mixins';

$mobileWidth: 620px;

$width-alert-kolonne: 2.5rem;
$width-dato-kolonne: 9rem;
$width-nummer-kolonne: 7rem;
$width-betalingsfrist-kolonne: 13rem;
$width-belop-kolonne: 8rem;

.fakturaoversikt {

  ol {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  .alert {
    width: $width-alert-kolonne;
  }

  .dato {
    width: $width-dato-kolonne;
  }

  .nummer {
    width: $width-nummer-kolonne;
  }

  .betalingsfrist {
    width: $width-betalingsfrist-kolonne;
  }

  .belop {
    width: $width-belop-kolonne;
    text-align: right;
  }

  .actions {
    padding-left: 2rem;
  }

  .table-header {
    padding: 1rem 0 0.4rem 1rem;
    border-bottom: 1px solid gainsboro;
    font-size: 0.85rem;
    font-weight: 700;

    @media (max-width: $mobileWidth) {
      display: none;
    }

    & > * {
      display: inline-block;
    }

    .betalingsfrist {
      margin-left: -0.5rem;
    }

    .belop {
      padding-right: 1.2rem;
    }
  }

  .table-row {
    display: flex;
    align-items: center;
    height: 5rem;
    padding-left: 0.8rem;
    border-bottom: 1px solid $border-table-color;

    .header {
      display: none;
      margin-right: 0.5rem;
      color: $small-text-lightest-gray;
    }

    .header:after {
      content: ': ';
    }

    @media (max-width: $mobileWidth) {

      display: block;
      height: auto;
      padding: 1rem 0.5rem;

      &:first-child {
        border-top: 1px solid $border-table-color;
      }

      .dato, .nummer, .betalingsfrist, .belop, .actions {
        display: block;
        width: auto;
        padding-top: 1rem;
      }

      .belop {
        text-align: unset;
      }

      .actions {
        padding-left:0;
      }

      .header {
        display: inline-block;
      }

    }

    &:hover {
      background-color: $row-hover-background-color;
    }

    button:hover {
      background-color: darken($button-background-color-hover, 10%);
    }

    & > * {
      &:not(:last-child) {
        padding-right: 1rem;
      }
    }
  }

  .betalte {
    max-width: 630px;
  }

}


