@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

$smallerAddressBreakPoint: 400px;


.fakturainformasjon {
  max-width: 650px;


  .feedback-full {
    margin-top: 0;
    line-height: 1.5rem;

    &__icon {
      align-self: center;
    }
    .icon {
      @include md-icon-size(2rem);
    }
  }

  input.basic-input, .react-select {
    max-width: 400px;
  }

  .input-group {
    border-radius: 7px;
    background-color: #fafafa;

    padding: 1rem 0 1rem 1rem;
    margin: 1rem 0 0 0;
    position: relative;

    legend {
      font-weight: bold;
      margin-bottom: 1rem;
      color: #6f6f6f
    }

    .spinner {
      width: 2rem;
      height: 2rem;
      border-width: .4rem;
      position: absolute;
      right: 1rem;
      top:.5rem;
    }

    .error-form-saving  {
      max-width: 400px;
    }

  }

  .input-fields {
    margin: 1rem 0;

    input {
      margin-bottom: 0;
      margin-left: 2px;
    }

    .error-message-in-place {
      margin: 0;
    }

    .bedrift-input-fields-wrapper {
      overflow: hidden;
      animation: slide-in .7s ease-out;
      margin-top: 1rem;


      .bedrift-input {
        margin-bottom: 1rem;
      }

      .legg-til-ny-verdi {
        padding: 1rem 0;
        margin-bottom: 1rem;
      }

      .input-organisasjonsnummer {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;

        &__input-and-error-wrapper {
          flex: 0 0 15rem;
          margin-right: 1rem;
        }

        input {
          max-width: 14rem;
        }
      }

      .organisasjonsnavn {
        padding: 0.5rem;
        margin: 1px 0;
        flex: 0 1 auto;
        background-color: #eeeeee;
        font-size: 1rem;
        border-radius: 0.5rem;

        &.in-line-spinner {
          margin: 0;
          background-color: white;

          .loader {
            width: 2rem;
            height: 2rem;
            border-width: .3rem;
          }
        }
      }
    }

  }

  .adresser-group, .privat-bedrift-group {
    fieldset {
      margin-bottom: 1rem;
    }
  }

  .fakturaadresse-input-fields {
    overflow: hidden;
    animation: slide-in .7s ease-out;

    border-radius: 7px;
    background-color: #fafafa;
    margin-top: 1rem;

    .input-with-label {
      margin-top: 1rem;
    }

  }

  .kontaktinfoadresse {
    border-radius: .5rem;
    border: 1px solid #ccc;
    max-width: 350px;
    margin: 1rem 0 1rem 1rem;
    padding: .5rem 1rem;
  }

}

@keyframes slide-in {
  0% {
    opacity: 0;
    max-height: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    max-height: 30rem;
  }
}