@import "../../sass/colors";
@import '../../sass/mixins';

$mobileWidth: 720px;


.aktive-kurs {

  ol {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    display:flex;
    flex-wrap: wrap;
  }

  li.aktive-kurs__row {
    flex-basis: 400px;
    flex-grow: 1;
    margin-right: 2rem;
  }

  &__row {

    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);


    .kurs {
      font-size: 1.5rem;
    }

    .datoer, .info {
      padding-top: 1rem;
    }
  }


}