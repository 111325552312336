@import '../sass/colors';
@import '../sass/responsivity';


$background-color: #f5f5f5;
$active-tab-color: #4c4c4c;
$active-border-thickness: 3px;

.tabs {
  &__panel {
    margin-bottom: 1rem;
    border-bottom: 1px solid lighten($font-color, 50%);

    display: flex;
    justify-content: flex-start;


    @media (max-width: $mobileWidth) {
      display: flex;
      justify-content: center;
    }


    button {
      flex: 0 1 auto;
      border-radius: 0;
      font-size: .8rem;
      font-weight: 600;
      color: lighten($active-tab-color, 15%);
      margin-right: 2px;
      padding: 1rem 2rem 0.9rem;
      background-color: white;

      @media (max-width: $mobileWidth) {
        padding: 1rem .5rem 0.9rem .5rem;
        flex-grow: 1;
      }

      &.active-tab {
        color: $active-tab-color;
        border-bottom: $active-border-thickness solid $active-tab-color;

        &:hover,  &:focus {
          border-bottom: $active-border-thickness solid $active-tab-color;
        }
      }

      &:hover, &:focus {
        $hover-border-color:  lighten($active-tab-color, 65%);
        color: lighten($active-tab-color, 10%);
        background-color: $hover-border-color;
        border-bottom: $active-border-thickness solid $hover-border-color;
      }
    }
  }

}