table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  margin: 0 0 20px 0;
}

table th, table td {
  text-align: left;
}

.table-light th, .ui-datatable.table-light th {
  padding: 8px 15px 7px 15px;
  text-align: left;
  font-weight: bold !important;
  border: 0;
}

.table-light tr {
  padding: 8px 15px 7px 15px;
  border-bottom: 1px solid #caced5;
}

.table-light tr.ui-widget-content > td, .table-light td {
  border: 0;
}

.table-light td {
  padding: 8px 15px 7px 15px;
}

.table-light td.delete {
  text-align: right;
}

.table-light td.delete a {
  text-decoration: none;
  border: none;
  color: #ce4c11;
  font-weight: bold;
}

.table-light#register {
  width: 100%;
  margin: 20px 0 0 0;
  border-top: 1px solid #caced5;
}