@import '../../../sass/colors.scss';
@import '../../../sass/mixins.scss';

.steg-oversikt {

  &__steg {

    margin-bottom: 2rem;
    max-width: 39rem;

    &:last-child {
      margin-bottom: 2.5rem;
    }

    h3 {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 1rem;
      @media (max-width: 350px) {
        font-size: 1rem;
      }
    }

    button {
      margin: 0 0 0 .5rem;
    }

    &__profil {
      margin-left: .2rem;

      & > div {
        margin-bottom: 1rem;

        .icon {
          margin-right: .5rem;
          vertical-align: -.4rem;
          height: 1.5rem;
          width: 1.5rem;
        }
        .adresse-icon {
          margin-right: 1rem;
        }


        &:last-child {
          margin-bottom: 0;
        }
      }

      &__personalia {

      }

      &__epost {

      }

      &__telefon {

      }

      &__adresser {
        display: flex;
        flex-wrap: wrap;

        &__adresse {
          flex: 0 0 275px;
          margin-right: 2rem;
          @media (max-width: 630px) {
            margin-bottom: 1rem;
          }

          &__icon {
            display: inline-block;
            vertical-align: top;
          }

          &__body {
            display: inline-block;
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__dokumentasjon {
      margin-left: .2rem;
      max-height: 200rem;
      overflow-y: auto;

      h4 {
        margin: 2rem 0 .5rem 0;

        &:first-child {
          margin: 1rem 0 .5rem 0;
        }
      }


      .tilknyttede-arbeidserfaringer-wrapper {
        ol {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }

        .briefcase-icon {
          vertical-align: -.25rem;
          margin-right: .35rem;
          @include md-icon-size(1.3rem);
        }

        .steg-oversikt__arbeidserfaring {
          border-radius: .5rem;
          background-color: $arbeidserfaringBgColorInactive;
          margin-bottom: .5rem;
          border-bottom: 1px solid $default-border-color-light;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .periode {
            span {
              font-size: .95rem;

              &.label {
                margin: 0 .3rem;
              }
            }
          }

          .periode, .arbeidsgiver-og-stillingstittel {
            margin: .5rem;
          }

          .vedlegg {
            margin: .4rem .5rem;
          }
        }

      }

      .gsk-vedtak {
        margin-bottom: 0.5rem;
        padding: 0;

        &.with-list {
          margin-top: 0.5rem;
          padding: 1rem 0 1rem .8rem;
        }

      }

    }

    &__fakturainfo {

      .icon {
        margin-right: .5rem;
      }

      &__betaler, &__bruk-kontaktinfoadresse {
        display: flex;
        align-items: center;
      }

      &__betaler {
        margin-bottom: 0.3rem;
      }

      &__bruk-kontaktinfoadresse {
        margin-top: 0.2rem;
      }

      & > div {
        line-height: 1.5rem;
      }

      .adresse {
        .icon {
          @include md-icon-size(1.5rem);
        }

        margin-top: 0.2rem;
        display: flex;
        align-items: center;
      }


    }


    &__sporreskjema {
      margin-left: .2rem;

      &__sporsmal {
        margin-bottom: 1.5rem;
        .icon {
          margin-right: .5rem;
          vertical-align: -.6rem;
          float: left;
          color: darken($icon-alert-color, 10%);
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__tekst {

        }

        &__svar {
          font-style: italic;
          margin: .5rem .5rem .5rem 2rem;
          color: $small-text-lightest-gray;

          &:before {
            content: '“';
          }

          &:after {
            content: '”';
          }
        }
      }
    }

    &__samtykker {
      margin-left: .2rem;

      &__samtykke {
        margin-bottom: 1.5rem;

        .icon {
          margin-right: .5rem;
          vertical-align: -.6rem;
          float: left;
          color: darken($icon-danger-color, 10%);
        }

        &:last-child {
          margin-bottom: 0;
        }

        &__besvarelse {
          font-style: italic;
          margin: .5rem .5rem .5rem 2rem;
          color: $small-text-lightest-gray;

          &:before {
            content: '“';
          }

          &:after {
            content: '”';
          }
        }
      }
    }

    &__planinfo {
      margin-left: .2rem;

      &__data {
        margin-bottom: 1rem;

        &__ikon {
          display: inline-block;
          width: 2.2rem;
          vertical-align: top;

          .icon {
            margin-right: .5rem;
            vertical-align: -.4rem;
          }
        }

        &__body {
          display: inline-block;
          width: calc(100% - 2.2rem);

          &__merknadtekst {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }

          &__bekreftelsesdato {
            margin-top: .2rem;
            color: $small-text-lightest-gray;
          }
        }
      }
    }

    &__eksamen {
      .icon {
        vertical-align: -.3rem;
        margin-right: .5rem;

        &.skal-ta-eksamen {
          color: $feedback-ok-icon-color;
        }

        &.skal-ikke-ta-eksamen {
          color: $feedback-error-icon-color;
        }
      }
    }

  }

}