@import './colors.scss';
@import './mixins.scss';

html, body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main#content {
  flex-shrink: 0; //IE fix
  flex-grow: 1;
}

.dot-delimiter {
  margin: 0 7px;
  color: #b3b3b3;
}

.text-light {
  color: #b3b3b3;
}


/* Felles */
.vis-for-sr {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.center {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.as-form-input {
  padding: 0 0 14px 0;
}

.as-form-input .as-label {
  display: block;
  margin: 0 0 7px 0;
  color: #767676;
}

.as-label {
  display: block;
  margin: 0 0 6px 0;
  color: #767676;
}

button.medium.icon-only {
  padding-left: 20px;
  min-width: 0;
  border: solid 1px transparent;
  background-color: transparent;
}

button.medium.icon-only:hover {
  border: solid 1px #cccccc;
  border-radius: 3px;
  background-color: #f0f0f0;
}

.Select {
  margin: 0 0 15px 0;
}

button.as-link {
  min-width: 0;
  background-color: #fff;
  border: 0;
  border-bottom: 1px dotted #0f6a88;
  margin-left: 20px;
  padding: 0;
  height: 17px;
  width: 34px;
  color: #0f6a88;
}

.padding-top {
  padding-top: 20px;
}

.menu-right {
  position: absolute;
  top: 0;
  right: 0;
}

.menu-left {
  position: absolute;
  top: 0;
  left: 0;
}

div#bar-wrap.no-height {
  height: 0;
}

form.profil {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 425px) {

  /* Felles */
  .center {
    width: 400px;
    margin: auto;
  }

  /* Profil */

  div.redigeringsform {
    display: block;
  }

  .redigeringsform button.cancel {
    display: none;
  }

  #rediger {
    display: none;
  }

  .profil-bilde-container div {
    display: block;
    text-align: center;
  }

  .profil-bilde-container img {
    margin: auto;
    display: block;
    padding-bottom: 10px;
  }

  .profil-opplysninger {
    width: 85%;
    margin: auto;
  }

  .personvernLink {
    text-align: center;
  }

  .profil-folkeregister-link {
    padding-bottom: 0;
  }
}

/*Soknader*/

/*mobile size*/
.hide-on-desktop {
  display: block;
}

td.hide-on-desktop {
  display: table-cell;
}

.hide-on-mobile, tr.hide-on-mobile {
  display: none;
}

.responsive-table tr td {
  display: block;
}

.soknad-row {
  font-size: 16px;
}

.title-column {
  font-size: x-large;
}

.soknad-row:first-child {
  border-top: 1px solid #caced5;;
}

.soknad-row td:first-child {
  padding-top: 12px;
}

.soknad-row td:last-child {
  padding-bottom: 12px;
}

/*desktop size*/
@media (min-width: 650px) {
  .hide-on-desktop, td.hide-on-desktop {
    display: none;
  }

  .hide-on-mobile {
    display: unset;
  }

  tr.hide-on-mobile {
    display: table-row;
  }

  td.hide-on-mobile {
    display: table-cell;
  }

  .responsive-table tr td {
    display: table-cell;
  }

  .soknader table > tbody > tr > td {
    height: 90px;
  }

  .title-column {
    font-size: 16px;
  }

  .soknad-row td:first-child {
    padding-top: 8px;
  }

  .soknad-row td:last-child {
    padding-bottom: 7px;
  }
}

.soknad-row:hover {
  background: #f9f8f6;
}

/* --> table-light-2*/

.table-light-2 tr:not(:last-child) {
  border-bottom: 1px solid gainsboro;
}

.table-light-2 > tbody > tr:hover {
  background-color: #F5F5F5;
}

.table-light-2 tr.selected {
  background-color: #FAFAFA;
}

.table-light-2 > thead > tr > th {
  color: rgba(0, 0, 0, .54);
  font-size: 14px;
}

.table-light-2 > tbody > tr:first-child {
  border-top: 1px solid gainsboro;
}

.modal .table-light-2 > tbody > tr:first-child {
  border-top: none;
}

.table-light-2 > tbody {
  font-size: 16px;
}

.table-light-2 td:last-child {
  padding-bottom: 12px;
}

.table-light-2 > tbody > tr > td, th {
  padding: 10px;
}

/* <-- table-light-2*/
.dokumenter .content-after-sticky {
  margin-top: -5px;
}

/*desktop size*/
@media (min-width: 500px) {
  .dokumenter .content-after-sticky {
    margin-top: 30px;
  }
}


.select-all {
  margin-right: 20px;
  display: inline-block;
  vertical-align: bottom;
}

.invisible {
  visibility: hidden;
}

.postnummer {
  display: inline-block;
}

.postnummer input[type="text"] {
  width: 100px;
}

.poststed {
  &.for-postnummer-input {
    margin-left: 10px;
  }
}

.modal {
  /* Probably need media queries here */
  width: 500px;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  border-radius: 10px;

  /* Use this for centering if unknown width/height */
  transform: translate(-50%, -50%);

  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);

  overflow: auto;
}

.modal.wide {
  width: 800px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.6);

}

.modal {

  .modal-content {
    overflow: auto;
    padding: 25px 25px 15px 25px;
    border-bottom: 2px dotted #eee;
  }

  &.ws-error {
    .modal-content {
      padding: 0;

      .feedback-full {
        margin: 0;
        border-radius: 0;

        &__info {
          background-color: #fff;
        }
      }
    }
  }
}


.modal-header {
  background-color: #f0f0f0;
  padding: 10px 5px 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-right: .3rem;
    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.modal-header h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}


.modal-header.wrap-long-header header {
  white-space: normal;
}


.modal-footer {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
}

a.button-like {
  background-color: #d2d2c7;
  border: 1px solid #c1bfb0;
  color: #222;
  text-align: left;
  background-repeat: no-repeat;
  background-position: 8px;
  height: 37px;
  font-size: 13px;
  min-width: 100px;
  padding: 10px 20px 10px 30px;
  white-space: nowrap;
  font-family: inherit;
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: super;
  position: relative;
  top: -3px;
}

a.button-like:hover {
  background-color: #e6e5da;
  border: 1px solid #c1bfb0;
}

.header-for-saksbehandling {
  position: relative;
  display: block;
  top: -20px;
  padding: 10px 15px;
  background-color: #fff5e5;
  font-size: .9rem;
  color: #555;
  text-align: left;

  strong {
    color: #e23e48;
  }

  .skriving-warning {
    font-size: .9rem;
    margin: 0 0 0 .5rem;

    .icon {
      @include md-icon-size(1.15rem);
      vertical-align: -.235rem;
      color: $feedback-warning-icon-color;
    }

    display: inline-block;
  }

  @media screen and (max-width: 500px) {
    text-align: center;
    padding: .5rem 0;
    font-size: .77rem;
    .skriving-warning {
      font-size: .77rem;
    }
  }
}

.ja-nei-container {
  width: 160px;
  max-width: 160px;
  @media (max-width: 500px) {
    margin-top: 8px;
  }

  .checkbox-container {
    display: inline-block;
    margin-bottom: 0;

    &:not(last-child) {
      margin-right: 1rem;
    }
  }

  legend {
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
  }
}

.flat-card {
  min-height: 100px;
  min-width: 0;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-sizing: border-box;
  margin: 10px;
  padding-left: 12px;
  padding-right: 12px;

  h2 {
    margin: 20px 10px;
  }
}

.flat-card .content {
  padding-bottom: 1rem;
}

.button-as-editable-text {
  text-align: unset;
  text-decoration: unset;
  color: unset;
  padding: unset;
  margin: unset;
  white-space: unset;
  font-family: inherit;
  font-size: inherit;
  position: unset;
  border: 1px white dotted;
  background: unset;
  width: 100%;
}

.button-as-editable-text:hover {
  border: 1px gray dotted;
}

.e-post input {
  width: 100%;
}

.inline-edit {
  fieldset {
    input {
      width: 100%;
    }
  }
  .error-form-saving {
    margin-bottom: 1rem;
  }
}

.clickable-area {
  cursor: pointer;
}

.clickable-area:hover, .clickable-area:focus {
  background-color: $clickable-area-hover-background-color;
  border-radius: 5px;
}

.clickable-area.disabled {
  cursor:    unset;
}

.clickable-area.disabled:hover, .clickable-area.disabled:focus {
  background-color: unset;
}

.extra-padding {
  padding: 10px;
}

.edit-mode {
  background-color: $edit-mode-bg-color;
  border-radius: 5px;

  label {
    margin-bottom: .25rem;
    display: block;
  }

  legend {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: $small-text-lightest-gray;
  }
}

.icon-for-label {
  margin-right: 5px;
  margin-bottom: -2px;
  display: inline-block;
}

.label-with-icon {
  display: inline-block;
}

.header-icon {
  margin-right: 5px;
  margin-bottom: -3px;
  display: inline-block;
}

.just-icon-in-header {
  .header-icon {
    margin-right: 10px;
  }
}

.field-header {
  color: $small-text-lightest-gray;
  margin-bottom: 4px;
}

.title-with-icon {
  display: inline-block;
}


.read-only-field.just-icon-in-header {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;

  .value {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}

.read-only-field.just-icon-in-header .field-header,
.read-only-field.just-icon-in-header .value {
  margin-bottom: 0;
}

.read-only-field .value {
  font-size: inherit;
}

.label-just-icon {
  margin-right: 10px;
  margin-top: 5px;
  float: left;
}

.just-icon-in-label {
  flex-grow: 100;
}

.input-with-label-just-icon {
  display: flex;

  .icon {
    margin-top: .05rem;
  }
}

.in-line-spinner {
  display: inline-block;
}

.legg-til-ny-verdi {
  color: $small-text-lightest-blue;
  .icon {
    color: currentColor;
    height: 1rem;
    width: 1rem;
    margin-right: .25rem;
    vertical-align: -2px;
  }
}

.vertically-aligned {
  display: flex;
  align-items: baseline;
}

.vertically-aligned.centered {
  align-items: center;
}

div:focus, button:focus, a:focus {
  outline: none;
  box-shadow: 0 0 0 2px $focus-outline-color;
}

fieldset {
  border: none;
  padding: 0;
}

.editable-field {
  white-space: nowrap;
  display: flex;
  align-items: center;

  & > div:first-child {
    flex-grow: 1;
  }

  .editable-indikator.icon {
    flex-shrink: 0;
    @include md-icon-size(1rem);
    margin: 0;
  }
}

.help-button {
  margin-right: 0;
  &.button {
    background-color: lighten($button-background-color, 5%);
  }
  .icon {
    padding: 0.2rem;
  }
}

.ws-error {
  h4 {
    margin: 0 0 10px 0;
  }

  &__description {
    display: inline-block;
    border-left: 3px solid #bbb;
    padding-left: 10px;
    font-style: italic;
    color: #666;
  }
}

.error-message-in-place {
  color: $error-message-color;
  font-style: italic;
  display: block;
  margin: -5px 10px 10px 0;
  padding: 0;
  white-space: normal;
}

.validate-field-error {
  border: solid 1px $error-input-border-color !important;

  &:focus {
    box-shadow: 0 0 0 1px $focus-outline-color-error !important;
  }
}

.select-validate-error {
  .react-select__control {
    border: solid 1px $error-input-border-color !important;
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px $focus-outline-color-error !important;
  }
}

.react-select__placeholder {
  span {
    color: $small-text-lightest-gray;
  }
}

#local-storage {
  p {
    margin: 1.5rem .8rem;
  }
}

.laster-data-container {
  display: block;
  padding: 0;
  margin: 1rem;

  .description {
    display: inline-block;
    margin-left: 1rem;
    max-width: calc(100% - 5rem);
  }
}

.external-link-icon {
  @include md-icon-size(1.2rem);
  vertical-align: -.3rem;
  margin-left: .4rem;
  color: $link-color;
}


.read-more {
  width: 100%;
  &__text {
    max-width: 35rem;
    max-height: 10000px;
    overflow: hidden;
    transition: max-height 1.5s ease-in;
    &:after {
      content: "";
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }
    &.collapsed {
      overflow: hidden;
      max-height: 9.6em;
      transition: max-height 0.2s ease-in-out;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50%;
        width: 100%;
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        opacity: 1;
      }
    }
  }
  .show-more-button {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.show-more-button {
  &.extended {
    .icon {
      transform: rotate(180deg);
      transition: 0.5s ease-in-out;
    }
  }

  .icon {
    transition: 0.5s ease-in-out;
  }
}


.status__standard {
  color: lighten($font-color, 10);
}

.status__avslag {
  color: $status-text-color;
  background-color: $status-background-color-red;
  padding: 0 .5rem;
}

.status__godkjent {
  color: $status-text-color;
  background-color: $status-background-color-green;
  padding: 0 .5rem;
}

.status__varsel {
  background-color: $status-background-color-yellow;
  padding: 0 .5rem;
}

.margin-zero {
  margin: 0;
}