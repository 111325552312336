@import '../../sass/colors';
@import '../../sass/mixins';

$mobileBreakPoint: 450px;

.soknader {

  &__liste {
    margin-top: 1.5rem;

    &__soknad {
      padding: .80rem 1rem 0 1rem;
      border-radius: 1rem;
      margin-bottom: 1.5rem;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);

      &__kursnavn {
        h2 {
          margin: 0;
          color: lighten($font-color, 5);
          word-wrap: break-word;
        }
      }

      &__status {
        display: inline-block;
        margin: .25rem 0 1rem 0;
        border-radius: .25rem;
        font-size: 1.2rem;

        strong {
          margin: 0;
          font-weight: normal;
        }
      }

      .feedback-full {
        padding: .5rem 1rem;
        min-height: 0 !important;
        margin-top: 0;

        &__text__no-icon {
          margin: 0;
        }
      }

      &__actions {
        .action {
          display: inline-block;
          margin: .25rem 0 1rem .5rem;
          padding: 0 .5rem 0 .5rem;
          border-radius: .5rem;
          white-space: nowrap;
          vertical-align: top;
          text-align: center;
          @media screen and (max-width: $mobileBreakPoint) {
            margin: .25rem 0 1rem 0;
            padding: 0 .5rem 0 .5rem;
            text-align: left;
          }

          button {
            margin: 0;
            padding: .25rem 1rem .25rem .25rem;
            background-color: transparent;
            text-align: left;
            letter-spacing: 0;
            font-size: 1rem;
            text-transform: none;
            border: 1px solid $border-table-color;
            .icon {
              color: $icon-color;
            }

            &:hover:not(:disabled) {
              background-color: lighten($button-background-color-hover, 2);

              .action__icon {
                .icon {
                  color: $icon-color-hover;
                }

                &__trekke {
                  color: $icon-danger-color-hover !important;
                }

                &.viktig {
                  background-color: lighten($button-primary-background-color-hover, 5);
                }
              }
            }

            &:disabled {
              .icon {
                vertical-align: -.2rem;
                color: lighten($icon-color, 20) !important;
              }
            }
          }

          & > .action__icon {
            padding-left: 1rem;
          }

          & > .action__text {
            padding: 0.5rem 0;
          }

          &__icon {
            display: inline-block;
            border-radius: 50%;
            padding: 0.5rem;

            .tooltip {
              left: 0;
              right: auto;
            }

            .icon {
              margin: 0;
              @include md-icon-size(1.7rem);
            }

            &.is-deleting-action {
              .icon {
                color: $icon-danger-color !important;
              }
            }

            &.viktig {
              background-color: $button-primary-background-color;
              margin-right: .25rem;

              .icon {
                color: $mdi-light-fill !important;
              }
            }
          }

          &__text {
            display: inline-block;
            vertical-align: .15rem;
            margin-left: .25rem;

            &.frist {
              vertical-align: -.45rem;

              .frist-label {
                display: block;
                color: lighten($font-color, 17%);
              }

              .frist-dato {
                display: block;
                font-size: 1.1rem;
                color: lighten($font-color, 5%);
              }
            }
          }

          &__frist {
            font-size: .9rem;
            margin-top: .5rem;
            @media screen and (max-width: $mobileBreakPoint) {
              margin-left: 1.5rem;
            }
          }
        }
      }
    }

  }

  .fade-transition {

    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity 500ms ease-in;
    }
  }


}