@import "../../../sass/colors";
@import "../../../sass/mixins";

.foretaksgodkjenning {
    table {
      margin-top: 1rem;
      margin-bottom: 0;
      border-collapse: collapse;
      tr {
        @media screen and (max-width: 800px){
          th {
            display: none;
          }
        }
        td, th {
          @media screen and (max-width: 800px){
            &.godkjenning, &.soker, &.status {
              display: none;
            }
          }
          @media screen and (min-width: 801px){
            &.alle-felt {
              display: none;
            }
          }
        }
        td {
          padding: .25rem .5rem;
          .epost {
            font-size: .8rem;
          }
          .status-soknad {
            max-width: 25vw;
          }
          .navn, .epost {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &:first-child, &.alle-felt {
            border-radius: .5rem 0 0 .5rem;
          }
          &.alle-felt{
            div {
              max-width: 60vw;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .icon {
              display: none;
            }
            .navn {
              margin-top: .25rem;
              margin-bottom: .1rem;
              font-size: 1rem;
            }
            .status-soknad {
              font-size: .8rem;
              margin-bottom: .25rem;
            }
          }
          &:last-child {
            text-align: right;
            padding-right: .5rem;
            border-radius: 0 .5rem .5rem 0;
            height: 3.25rem;
            width: 7.5rem;
            @media screen and (max-width: 375px) {
              width: 3.9rem;
            }
            .loader {
              width: 2.5rem;
              height: 2.5rem;
              border-width: .2rem;
            }
            button {
              background: #fff;
              border: 1px solid $border-table-color;
              &.avvis {
                margin-right: .5rem;
                .icon {
                  color: $foretaksgodkjenningAvvisColor;
                }
              }
              &.godkjenn {
                margin-right: 0;
                .icon {
                  color: $foretaksgodkjenningGodkjennColor;
                }
              }
              &.endre {
                margin-right: 0;
              }
              &:hover {
                background-color: lighten($button-background-color-hover,2);
              }
              &:disabled {
                background-color: lighten($button-background-color-hover,2) !important;
                .icon {
                  color: #cdcacd;
                }
              }
              @media screen and (max-width: 375px) {
                margin-right: 0 !important;
              }
            }
          }
        }
        th {
          padding: .5rem 1rem;
        }
        .status-godkjenning {
          font-weight: bold;
          .icon {
            vertical-align: -.35rem;
            margin-right: .25rem;
          }
        }
        &.ny {
          .status-godkjenning {
            color: #767600;
            .icon {
              color: currentColor;
            }
          }
        }
        &.godkjent {
          .status-godkjenning {
            color: #1E600B;
            .icon {
              color: currentColor;
            }
          }
        }
        &.avvist {
          .status-godkjenning {
            color: #90272B;
            .icon {
              color: currentColor;
            }
          }
        }
        &:nth-child(odd) {
          td {
            background-color: #f9f8f7;
          }
        }
      }
    }
}