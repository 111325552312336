@import '../sass/colors.scss';

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.focused {
    .checkbox-checkmark {
      outline: none;
      box-shadow: 0 0 0 2px $focus-outline-color;
      &.loading {
        box-shadow: none;
      }
      &:not(.loading) {
        border: 1px solid transparent;
      }
    }
  }
}

.checkbox-container.without-label {
  height: 25px;
  padding-left: 15px;
  margin-bottom: 0;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 1px;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: -3px;
  left: 5px;
  height: 20px;
  width: 20px;
  background-color: $checkbox-background-color;
  border: 1px solid $checkbox-border-color;
  border-radius: 3px;
  transition: 0.3s ease-out;
  &.loading {
    $border-width: 3px;
    border-radius: 50%;
    border-right: $border-width solid $checkbox-border-color;
    border-top: $border-width solid $checkbox-border-color;
    border-left: $border-width solid $checkbox-background-color;
    border-bottom: $border-width solid $checkbox-background-color;
    background-color: #fff;
    animation: rotationAnimation linear .7s;
    animation-delay: 0.3s;
    animation-iteration-count: infinite;
    &:after {
      opacity: 0;
    }
  }
}
.checkbox-container.focused {
  .checkbox-checkmark {
    background-color: #fff !important;
    border-color: $focus-outline-color !important;
  }
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: $checkbox-background-hover-color;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  &:not(.loading){
    background-color: $checkbox-background-checked-color;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox-container.focused .checkbox-checkmark:after {
  border-color: $focus-outline-color !important;
}