@import '../../sass/less-fun-variables';
@import '../../sass/mixins';

$barHeight: 2.8rem;

#menu-bar {
  background: $navbarWrapperColor;
  max-height: $barHeight;
  &__dropdowns {
    position: relative;
    display: flex;
    margin: 0 auto;
    max-width: $containerWidth;
    @media screen and (max-width: $containerBreakPoint) {
      max-width: 100%;
      margin: 0;
    }
    .menubar-button {
      color: $navbarTextColor;
      @media screen and (min-width: $mobileBreakPoint) {
        position: relative;
      }
      & > a:focus, button:focus{
        box-shadow: none;
        outline: solid 2px #55AAFF;
        outline-offset: -2px;
      }
      & > a {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        height: 100%;
        cursor: pointer;
        font-size: .8rem;
        min-width: 3rem;
        color: $navbarTextColor;
        text-decoration: none;
        .icon {
          color: currentColor;
          vertical-align: -.65rem;
          margin-right: .5rem;
          @include md-icon-size(2rem);
          &.institusjon-icon {
            vertical-align: -.45rem;
            @include md-icon-size(1.7rem);
          }
        }
        @media screen and (max-width: 700px) {
          .icon {
            margin-right: 0;
          }
          span {
            position: absolute;
            overflow: hidden;
            height: 1px;
            width: 1px;
          }
        }
      }
      .dropdown {
        &__toggler {
          cursor: pointer;
          padding: .45rem 1rem !important;
          font-size: .8rem;
          height: $barHeight;
          min-width: 3rem;
          text-align: center;
          position: relative;
          z-index: 101;
          white-space: nowrap;
          text-transform: none;
          background-color: transparent;
          color: $navbarTextColor;
          border-radius: 0;
          margin: 0;
          @media screen and (max-width: 400px) {
            padding: .45rem .25rem;
          }
          .icon {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            @include md-icon-size(2rem);
            color: currentColor;
          }
          &__text {
            vertical-align: middle;
            max-width: 29vw;
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &__shortcut {
            display: none;
          }
          .utvid-icon {
            transition: .2s ease-in;
          }
        }
        &__links {
          position: absolute;
          left: 0;
          top: $barHeight;
          overflow: hidden;
          background-color: $navbarExtendedBackgroundColor;
          z-index: 100;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
          border-radius: 0 0 .5rem .5rem;
          max-height: 0;
          min-width: 10rem;
          margin: 0;
          padding: 0;
          @media screen and (max-width: $mobileBreakPoint) {
            width: 100vw;
            border-radius: 0 !important;
          }
          a {
            white-space: nowrap;
            display: block;
            padding: 1rem 1.5rem;
            border-radius: .4rem;
            color: $navbarLinkColor;
            text-decoration: none;
            margin: .13rem;
            &:hover, &:focus {
              background-color: $navbarLinHoverBackgroundColor;
              color: $navbarLinHoverColor;
              outline-color: #2277FF;
            }
            .icon {
              color: currentColor;
              vertical-align: -.35rem;
              margin-right: 1rem;
            }
          }
        }
        &.extended {
          .dropdown__links {
            max-height: 1000px;
            transition: 0.5s ease-in-out;
          }
          .dropdown__toggler {
            background-color: $navbarExtendedBackgroundColor;
            color: $navbarWrapperColor;
            .icon {
              color: $navbarWrapperColor;
            }
          }
          .utvid-icon {
            transform: rotate(-180deg);
          }
        }
        &.collapsed {
          .dropdown__links {
            a {
              display: none;
            }
          }
        }
        &.clicked {
          .dropdown__toggler {
            z-index: 100;
          }
        }
      }
      &:last-child {
        .dropdown {
          &__links {
            left: auto;
            right: 0;
            @media screen and (max-width: $containerBreakPoint) {
              border-radius: 0 0 0 .5rem;
            }
          }
          &__toggler__text {
            @media (max-width: 420px) {
              position: absolute !important;
              height: 1px;
              width: 1px;
              overflow: hidden;
              clip: rect(1px, 1px, 1px, 1px);
            }
          }
        }
      }
    }

    .hovedlenker {
      margin-right: auto;
      white-space: nowrap;
      display: flex;
    }
  }
  &__content-link {
    a {
      display: block;
      position: absolute;
      top: 0;
      left: -2000px;
      height: $barHeight;
      padding: .8rem 1rem;
      background-color: $navbarLinkColor;
      color: $navbarContentLinkColor;
      text-decoration: none;
      z-index: 101;
      &:focus {
        left: 0;
      }
    }
  }
}