@import "../../../sass/colors";
@import "../../../sass/mixins";

.foretaksgodkjenning {
  .kurs-actions {
    button {
      margin-top: .5rem;
      background: transparent;
      border: 1px solid $border-table-color;
      &:hover {
        background-color: lighten($button-background-color-hover, 2);
      }
      &.avvis {
        .icon {
          color: $foretaksgodkjenningAvvisColor;
        }
      }
      &.godkjenn {
        .icon {
          color: $foretaksgodkjenningGodkjennColor;
        }
      }
    }
  }
}