@import '../../sass/colors';

.inline-edit {
  padding: 10px;

  &--edit-mode {
    background-color: $clickable-area-hover-background-color;
    border-radius: 5px;

    legend {
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: bold;
      color: $small-text-light-gray;
    }
  }
}

.inline-edit--presentation-mode.inline-edit--saved-animation {
  animation: fadeOut 1s ease-out;
  border-radius: .5rem;
}

@keyframes fadeOut {
  0% {
    background-color: rgba(227, 237, 247, 1);
  }
  100% {
    background-color: rgba(227, 237, 247, 0);
  }
}