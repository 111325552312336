.steg-profil {

  .feedback-full {
    margin-left: 10px;
    max-width: 830px;
    @media screen and (max-width: 830px){
      max-width: calc(100% - 20px);
    }
  }

}