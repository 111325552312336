@import '../../../sass/colors';
@import '../../../sass/mixins';

$singleColumnCutoff: 800px;

.legg-til-button {
  button {
    &:hover {
      background-color: #f8f8f8 !important;
    }
  }
}

.legg-til-endre-arbeidserfaring {
  margin-bottom: .2rem;
  .edit-form {
    h2 {
      margin-top: 0;
    }
    background: $arbeidserfaringBgColor;
    padding: 1rem;
    border-radius: .5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    label {
      display: block;
      margin-bottom: .2rem;
      @include sentence-case();
    }
    input {
      max-width: 24rem;
      &[type="date"] {
        max-width: 12rem;
      }
      &[type="number"] {
        max-width: 11.3rem;
      }
    }
    .periode {
      @media screen and (min-width:$singleColumnCutoff) {
        display: flex;
        flex-wrap: wrap;
        & > div {
          vertical-align: bottom;
          margin-right: 1rem;
          &:nth-child(3){
            padding-top:2.2rem;
          }
        }
      }
      .error-message-in-place {
        width: 100%;
      }
      @media screen and (max-width:$singleColumnCutoff) {
        margin-bottom: 1rem;
        .error-message-in-place {
          margin-top: .5rem;
        }
      }
    }
    .ws-error {
      max-width: 35rem;
      border-radius: .5rem;
      margin: 1rem .2rem;
      border: 1px solid #bbb;
    }
    .actions {
      button {
        margin-top: 1rem;
      }
    }
  }
}