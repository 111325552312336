@import '../../../sass/colors';
@import '../../../sass/mixins';

.kursmangler-wrapper {
  max-width: 45rem;
  position: relative;

  .mer-info-button {
    position: absolute;
    top: .3rem;
    right: .2rem;
    margin-right: 0;
  }

}

.kursmangler {

  background-color: $feedback-background-color-warning;
  border: 1px solid lighten($feedback-warning-icon-color, 20%);;
  padding: 1rem;
  border-radius: 1rem;
  line-height: 1.5rem;

  h4 {
    margin:0;
    //font-size: 1.1rem;
  }

  .icon {
    @include md-icon-size(1.6rem);
    margin-right: .5rem;
    vertical-align: -.35rem;
  }


  li {
    margin-top: 1rem;
    .punkt {
      color: $icon-color;
      margin-right: 1rem;
    }

    &:last-child {
      margin-bottom: 1rem;
    }
  }


}