@import '../../sass/colors';

.sporreskjema-sporsmal {

  position: relative;

  @media screen and (max-width: 500px) {
    margin: .5rem 0 !important;
  }

  &__svar {

    margin: 0 10px;

    .valgfritt {
      font-size: 15px;
      color: $sporreskjema-valgfri-color;
      margin-top: .25rem;
      font-weight: bold;
    }

    .error-message-in-place {
      margin-top: 10px;
      margin-bottom: 5px;
      button {
        vertical-align: -.2rem;
        @media screen and (max-width: 500px) {
          display: block;
          margin-left: 0;
          margin-top: .5rem;
        }
      }
    }

    .laster-data-container {
      position: absolute;
      right: .1rem;
      top: .1rem;
      padding: .5rem;
      opacity: 0;
      transition: .25s ease-out;
      .loader {
        margin-right: 0;
        width: 1.5rem;
        height: 1.5rem;
        border-width: .25rem;
      }
      .description {
        display: none;
      }
    }

    .loading {
      .laster-data-container {
        opacity: 1;
      }
    }

    &__polar {
      legend {
        width: 100%;
        float: left;
        font-size: 1.1rem;
        font-weight: bold;
        margin: 1rem 0;
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
      .checkbox-container {
        clear: both;
      }
    }

    &__freetext {

      padding-top: 20px;

      input[type='text'] {
        margin-bottom: 0 !important;
      }

      label {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.1rem;
        font-weight: bold;
      }

    }

  }

}