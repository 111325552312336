@import './../sass/colors.scss';

$radioSize: 1.5rem;
$radioBorder: #707070;
$radioActive: #0067C5;

.radio {
  &:not(:last-child){
    margin-bottom: 1rem;
  }
  display: block;
  cursor: pointer;

  &.checked {
    font-weight: bold;
    color: #6f6f6f;
  }

  input {
    height: $radioSize;
    width: $radioSize;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;

    &:focus + span:before{
      box-shadow: 0 0 0 2px $focus-outline-color;
      outline: 3px solid transparent;
    }

    & + span {
      line-height: $radioSize;
      height: $radioSize;
      padding-left: $radioSize;
      display: block;
      position: relative;

      &:not(:empty) {
        padding-left: $radioSize + .8rem;
      }

      &:before,
      &:after {
        content: '';
        width: $radioSize;
        height: $radioSize;
        display: block;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute;
      }

      &:before {
        background: $radioBorder;
        transition: background .2s ease, transform .4s cubic-bezier(.175, .885, .32, 2);
      }


      &:after {
        background: #fff;
        transform: scale(.78);
        transition: transform .6s cubic-bezier(.175, .885, .32, 1.4);
      }
    }

    &:checked + span {
      &:before {
        transform: scale(1.04);
        background: $radioActive;
      }

      &:after {
        transform: scale(.4);
        transition: transform .3s ease;
      }
    }
  }

  &:hover {
    input {
      & + span {
        &:before {
          transform: scale(.92);
        }

        &:after {
          transform: scale(.74);
        }
      }

      &:checked + span {
        &:after {
          transform: scale(.4);
        }
      }
    }
  }
}