@import '../../../sass/colors';


.soknadsdokumenter {
  $krav-background-color: #f8f8f8;
  .opptakskrav-wrapper {
    max-width: 45rem;

    padding: 1rem 2.5rem 1rem 1rem;
    background-color: $krav-background-color;
    border-radius: 1rem;
    margin: 0 0 1rem 0;
    position: relative;

    .file-types-info-button {
      position: absolute;
      top: .3rem;
      right: .2rem;
      margin-right: 0;
    }

    .opptakskrav {
      .merknad-krav {
        margin-top: 1rem;
      }
      .read-more {
        &__text.collapsed {
          &:after {
            background: linear-gradient(rgba(255, 255, 255, 0), $krav-background-color);
          }
        }
      }

    }
  }
}

.opptakskrav {

  line-height: 1.5rem;

  .nettside-krav {
    margin-top: 1rem;
  }

  .punkt {
    color: $icon-color;
    margin-right: 1rem;
  }

  .merknad-krav {
    position: relative;

    .punkt {
      position: absolute;
      left: 0;
      top: .1rem;
    }
  }

  .read-more {
    padding-left: 1.5rem;

    .show-more-button {
      margin-left: -1rem;
      margin-top: .2rem;
    }

    &__text.collapsed {
      max-height: 6em;
    }
  }
}

.modal-content {

  .nettside-krav {
    margin-bottom: 2rem;
  }

}