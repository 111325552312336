@import "../../sass/colors";
@import "../../sass/mixins";
@import "../../sass/less-fun-variables";

$border-color: lighten($default-border-color, 25%);
$header-background-color: lighten($border-color, 4%);
$hover-background-color: lighten($header-background-color, 2%);

$heightBreakPoint: 850px;

.login {

  text-align: center;

  h2 {
    background-color: $header-background-color;
    border-radius: 1rem 1rem 0 0;
    margin: 0;
    padding: 1rem;

  }

  &__box-and-links-wrapper {
    max-width: 520px;
    margin: 32px auto;
    @media screen and (max-width: $mobileBreakPoint) {
      margin: 1rem auto;
    }

  }

  &__box {
    border-radius: 1rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
    margin: 32px 0;
    @media screen and (max-width: $mobileBreakPoint) {
      margin: 1.5rem 0;
    }
  }

  &__sosial-media {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2rem;

    .sosial-media-button {
      margin-top: 1.5rem;
    }
  }

  &__methods-separator {
    color: $small-text-lightest-gray;
    position: relative;
    margin-top: -1rem;

    .separator {
      width: 100%;
      height: 1px;
      top: 16px;
      margin: 0 auto;
      background: $border-color;
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
    }

    .text {
      width: 4rem;
      height: 2rem;
      line-height: 30px;
      margin: 0 auto;
      border-radius: 2rem;
      color: #666;
      background: #fff;
      border: 1px solid $border-color;
      font-size: 16px;
      z-index: 2;
      position: relative;
    }

  }

  &__other-methods {
    list-style-type: none;
    padding-left: 0;
    text-align: left;

    li {
      border-top: 1px solid $border-color;
      padding: 2px 0;
    }

    margin: 0;
  }

  .sosial-media-button {
    padding-left: 20px;
    padding-right: 25px;
    align-items: center;
    display: flex;
    text-transform: none;
    font-size: 1rem;
    color: white;

    .icon {
      @include md-icon-size(1.5rem);
      color: white;
    }
  }

  .sosial-media-button.facebook {
    background-color: #3b5998;

    &:hover {
      background-color: #293e69;
    }
  }

  .sosial-media-button.twitter {
    background-color: #5aa4eb;

    &:hover {
      background-color: #3b82da;
    }
  }

  .sosial-media-button.linkedin {
    background-color: rgb(26, 129, 185);

    &:hover {
      background-color: rgb(7, 112, 169);
    }

    .icon {
      margin-top: -5px;
    }
  }


  .button-with-description {
    display: flex;
    align-items: center;
    padding: 2rem;

    &:hover {
      background-color: $hover-background-color;
    }

    &__logo {
      height: 3rem; //for IE
      img {
        height: 100%; //for IE
      }
    }

    .description {
      margin-left: 1.5rem;

      &__tittel {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 2rem;
      }
    }

  }


  .personvern-link {

    &--show-only-desktop {
      display: inline-block;
      @media screen and (max-height: $heightBreakPoint) {
        display: none;
      }
    }

    &--show-only-mobile {
      display: none;
      @media screen and (max-height: $heightBreakPoint) {
        display: inline-block;
      }
    }
  }


  &__links {
    list-style-type: none;
    padding-left: 0;

    .link {
      margin-bottom: 10px;
    }
  }

}

.innloging-feilet {
  padding-bottom: 1rem;

  .feedback-full {
    max-width: 600px;
  }

  .fortsatt-palogget {
    .button {
      margin-left: 1rem;
      margin-top: .2rem;
    }
  }

}