@import '../../../sass/colors';
@import '../../../sass/mixins';

.arbeidserfaring__dokumentasjon {
  .dokument {
    .clickable-area {
      display: inline;
      &:hover {
        border-radius: 0;
        background-color: inherit;
      }
    }
    & > .icon {
      color: #444;
      margin-right: .25rem;
      vertical-align: -.325rem;
    }
    button.delete {
      margin-left: .75rem;
      .icon {
        color: darken($icon-color, 5%);
      }
    }
  }
  .dropzone {
    border: dashed 1px #888;
    max-width: 35rem;
    min-height: 2.3rem;
    .loader {
      width: 1rem;
      height: 1rem;
      border-width: .2rem;
      margin-right: .5rem;
    }
  }
  .ws-error {
    max-width: 35rem;
    border-radius: .5rem;
    margin: 1rem .2rem;
    border: 1px solid #bbb;
  }
  button.prov-igjen {
    padding: .5rem .8rem .5rem .5rem;
    .icon {
      vertical-align: -.325rem;
    }
  }

  .error-message-in-place {
    margin-top: .5rem;
  }
}