@import './../../sass/less-fun-variables';

header.fun {
  position: relative;
  border-bottom: 1px dashed #d2d6dd;
  padding: 20px 0 10px 0;
  width: 100%;
  h1 {
    display: inline-block;
    margin: 26px 0 3px 0;
  }
  a {
    color: #444;
    text-decoration: none;
    white-space: nowrap;
    display: block;
    min-height: 4rem;
    max-width: 40rem;
    @media screen and (max-width: $mobileBreakPoint) {
      min-height: 0;
      padding-left: 1rem;
    }
  }

  .logo {
    margin-right: 20px;
    height: 64px;
    float: left;

    @media screen and (max-width: $mobileBreakPoint) {
      display: none;
    }
  }

  .appilacation-name {
    font-size: 32px;
    font-weight: bold;
    @media screen and (max-width: $mobileBreakPoint) {
      font-size: 20px;
    }
  }

  .institution-name {
    margin: 5px 0 10px 0;
    font-size: 18px;
    white-space: normal;

    @media screen and (max-width: $mobileBreakPoint) {
      font-size: 16px;
    }
  }

}
