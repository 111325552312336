@import '../../../sass/colors';

.steg-skal-ta-eksamen {

  padding: 1.3rem !important;
  h3 {
    margin: 0;
  }
  p {
    margin: 1rem 0 1.25rem 0;
  }
  .error-message-in-place {
    margin: 0 0 0 1rem;
  }

}