@import './colors';

@mixin md-icon-size($size: 24px) {
  height: $size;
  width: $size;
}

@mixin sentence-case() {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}


@mixin default-border() {
  border: 1px solid $default-border-color-light;
  border-radius: $default-border-radius;
}