@import '../../sass/colors';

.logg-inn-som-bruker-form {
  .bruker-type {
    background-color: $feedback-background-color-default;
    margin-bottom: 1rem;
    padding: 1.2rem 1rem;
    border-radius: .5rem;
    label {
      margin-left: 0;
    }
  }
  .bruker-nummer {
    label {
      display: block;
      font-size: .8rem;
      margin-bottom: .5rem;
    }
  }
  .checkbox-container {
    margin-top: 1rem;
  }
  .error-message-in-place {
    margin-top: .75rem;
  }
  .radio {
    display: inline-block;
    margin: 0 1rem;
  }
}