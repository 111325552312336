@import '../../sass/colors';
@import '../../sass/mixins';

$smallerAddressBreakPoint: 400px;

.adresse-sperret {
  .input-adresse-presentasjon {
    padding: 10px;
  }
}

.input-adresse-presentasjon {
  @media screen and (max-width: $smallerAddressBreakPoint) {
    font-size: 0.9rem;
  }

  display: flex;
  align-items: flex-start;

  .adresse {
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
  }

  .legg-til-ny-verdi {
    display: inline-block;
    align-self: center;
    @media screen and (max-width: $smallerAddressBreakPoint) {
      margin-top: 0.2rem;
    }
  }

  .editable-indikator.icon {
    @include md-icon-size(1rem);
    margin: 0;
  }
}

.adresse-icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 1.5rem;

  &.icon {
    @include md-icon-size(3rem);
  }

  @media screen and (max-width: $smallerAddressBreakPoint) {
    &.icon {
      @include md-icon-size(1.5rem);
    }
    margin-right: .75rem;
  }
}

.adresse {
  address {
    font-style: normal;
  }
  &__tekst {
    display: inline-block;
    line-height: 1.5em;
    max-width: 320px;
    @media screen and (max-width: 320px) {
      max-width: 55vw;
    }
  }
}

