@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';


.pamelding {

  h1 {
    font-size: 1.8rem;
    @media only screen and (max-width: 750px) {
      font-size: 1.3rem;
    }
    @media only screen and (max-width: 500px) {
      font-size: 1.1rem;
    }
  }

  & > strong {
    font-size: 1.5rem;
    margin: .1rem .6rem 1rem .6rem;
    display: inline-block;
    border-radius: .35rem;
    @media only screen and (max-width: 750px) {
      font-size: 1rem;
    }
    @media only screen and (max-width: 500px) {
      font-size: 1rem;
    }

    &.standard {
      color: lighten($font-color, 17);
    }
  }

  & > .feedback-full {
    margin-top: 0;
  }

  & > h3 {
    margin: .1rem .6rem 1rem .6rem;
    color: $pamelding-steg-error-color;
  }

  &__opprettet {
    .link {
      margin-left: 1.5rem;
    }
  }

  .steg {

    padding: 0 15px 0 0;

    .stegnavn {
      display: inline-block;

      .optional {
        color: black;
        opacity: 0.58;
      }
    }

    .stegnummer {
      margin: 10px 10px 10px 0;
      border-radius: 50%;
      height: 28px;
      width: 28px;
      border: solid $pamelding-steg-nummer-inactive-border-color 1px;
      background-color: $pamelding-steg-nummer-inactive-background-color;
      color: #707070;
      min-width: 28px;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      padding: 4px 4px 1px 5px;
      display: inline-block;
      z-index: 1;
      position: relative;
    }

    .steg-completed-icon {
      vertical-align: -1.1rem;
      background-color: $pamelding-steg-active-color;
      color: $mdi-light-fill;
      padding: .25rem;
      border-radius: 50%;
      @include md-icon-size(1.75rem);
      margin: 10px 10px 10px 0;
    }

    .steg-ikke-nodvendig {
      margin-top: .5rem !important;
      max-width: 54rem;
      @media screen and (max-width: 350px) {
        .feedback-full__icon {
          display: none;
        }
        .feedback-full__text {
          margin-left: 0;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .container {
      margin: 0 0 0 13px;
      padding: 10px 0 20px 15px;
      border-left: 1px solid $pamelding-steg-container-left-border-color;
      overflow: hidden;

      .actions {
        margin-top: 15px;

        button {
          margin-right: 10px;
        }
      }
    }

    &.active {
      .container {
        .content {
          opacity: 1;
          transition: opacity .5s ease-out;
        }
      }

      .stegnavn .name {
        font-weight: bold;
      }

      .stegnummer {
        border: solid $pamelding-steg-active-color 1px;
        background-color: $pamelding-steg-active-color;
        color: white;
      }

      @media only screen and (max-width: 425px) {
        .container {
          border-left: none;
          margin: 0;
        }
      }
    }

    &.clickable {
      .velg-steg:hover {
        background: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
      .stegnummer {
        color: #050505;
      }
    }

    &.inactive {
      .container {
        max-height: 15px;
        .content {
          display: none;
          opacity: 0;
        }
      }

      &:not(.clickable) {
        .stegnavn {
          color: black;
          opacity: 0.58;
        }
      }
      &.clickable {
        .stegnavn {
          color: black;
          opacity: 0.80;
        }
      }
    }

    &.error {
      .stegnavn {
        color: $pamelding-steg-error-color;
        font-style: italic;
        opacity: unset;
      }
    }
    .inline-block {
      display: inline-block;
    }
  }

  .main-info {
    margin-right: 15px;
  }
}
