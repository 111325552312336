@import './../../sass/colors.scss';

.profil {
  .read-only-field {
    padding: 10px;
  }

  .editable-field {
    .read-only-field {
      padding: 0;
    }

    &--disabled {
      .read-only-field {
        padding: 10px;
      }
    }
  }

  .cards-container {
    max-width: 850px;
    align-items: flex-start;

    .flat-card {
      flex: 1 1 250px;
    }
  }

}

