@import '../sass/colors';

.error-form-saving {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__text-wrapper {
    margin-right: 2rem;
    margin-top: 1rem
  }

  h4 {
    margin: 0 0 .5rem 0;
    color: #b33c22;
  }

  padding: 0 1rem 1rem 1rem;
  background-color: #ffecea;
  border-radius: 5px;

  .ws-error-description {
    margin-top: .5rem;
  }

  .button {
    margin-top: 1rem;
    background-color: darken($button-background-color, 5%);
    &:hover {
      background-color: darken($button-background-color, 10%);
    }
  }
}