@import "../sass/colors";

.ws-error-description {
  border-left: 3px solid #707070;
  padding-left: 10px;
  font-style: italic;
  color: #666;

  .show-more-button {
    margin-left: .5rem;
  }

  p {
    margin: .5rem 0;
  }

  &.feedback-ws-error {
    border-left: none;
    padding: 0;
    color: $font-color;
    font-style: normal;

    .ws-error-description__details {
      border-left: 3px solid #707070;
      padding-left: 10px;
      font-style: italic;
      color: #666;
    }

  }
}