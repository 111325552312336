@import "../../sass/colors";

$mobileWidth: 860px;

.fakturaoversikt .utestaende {

  max-width: 870px;
  @media (max-width: $mobileWidth) {
    //max-width: 560px;
  }

  .table-header {
    @media (max-width: $mobileWidth) {
      display: none;
    }
    .belop {
      padding-right: 1rem;
    }
  }

  .table-row {

    &.purret {
      height: 6rem;
      @media (max-width: $mobileWidth) {
        height: auto;
      }
    }

    &.purret, &.frist-utgar-snart {

      color: $error-message-color;
      font-weight: bold;


      .alert {
        .icon {
          color: $error-message-color;
        }
      }

      .betalingsfrist {

        &__old-value, &__purret-header, &__value {
          display: block;
        }

        &__old-value, &__purret-header {
          margin-bottom: 0.3rem;
        }

        s {
          color: $small-text-lightest-gray;
          font-weight: normal;
        }
      }

    }

    @media (max-width: $mobileWidth) {

      display: block;
      height: auto;
      padding: 1rem 0.5rem;
      position: relative;

      &:first-child {
        border-top: 1px solid $border-table-color;
      }

      .dato, .nummer, .betalingsfrist, .belop, .actions {
        display: block;
        width: auto;
        padding-top: 1rem;
      }

      .belop {
        text-align: unset;
      }

      .actions {
        padding-left:0;
      }

      .header {
        display: inline-block;
      }

      .alert {
        position: absolute;
        top: 1.5rem;
        right: 0;
      }

      .betalingsfrist {
        .wrapper-1, .wrapper-2 {
          display: inline-block;
        }
      }

      &.purret {
        .betalingsfrist {
          .wrapper-1, .wrapper-2 {
            display: block;
          }

          .wrapper-2 {
            margin-top: 0.5rem;
          }
        }
      }


      &.purret, &.frist-utgar-snart {

        .betalingsfrist {

          &__old-value, &__purret-header, &__value {
            display: inline-block;
          }

          &__purret-header {
            margin-right: 0.5rem;
          }
        }

      }

    }


  }


}

