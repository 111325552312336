@import '../../sass/mixins';


/*Card*/
.card {
  margin: 15px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
  height: calc(100% - 30px);
  min-height: 205px;
  display: flex;
  flex-direction: column;
}

.card:hover {
  /*border: 1px solid #d2cbcb;*/
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .2);
}

.card-header {

  display: flex;
  align-items: center;
  border-bottom: 1px solid #dde1e8;
  margin: 1rem;
  padding-bottom: .75rem;
  .icon {
    @include md-icon-size(1rem);
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    margin: 0;
    flex: 1 1 100%;
    display: block;
  }

}

.card-content {
  padding: 16px;
  padding-top: 8px;
  flex-grow: 1;
  display: flex;
}

.card-content > p {
  margin: 0;
  font-size: 16px;
}

.card-actions {
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.card-actions-button {
  margin: 8px 12px 6px 8px;
}

.card-actions-link {
  align-self: flex-end;
  margin: 8px;
  margin-right: 0;
  text-transform: uppercase;
}

.card-icon {
  margin-right: 12px;
  vertical-align: middle;
}

/*Min side*/
.cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}