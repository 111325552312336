@import '../../sass/colors';
@import '../../sass/mixins';

.kursinfo {

  $mobileBreakPoint: 500px;

  h1 {
    word-wrap: break-word;
  }

  &__tags {
    & > span {
      background-color:  $kurs-kategori-std-bg-color;
      color: #fff;
      display: inline-block;
      padding: .2rem .75rem;
      margin: .5rem 0 .25rem .5rem;
      border-radius: .3rem;

      &.nettbasert {
        background-color: $kurs-kategori-std-nettbasert-color;
      }
      &.eksamen {
        background-color: $kurs-kategori-std-eksamen-color;
      }
    }
  }

  .kursets-nettside{
    display: inline-block;
    margin: 0 0 1rem 0.5rem;
  }


  .pamelding-button, .endre-soknad-button {
    display: block;
    margin: 0.5rem 0 1.5rem;

    &:first-of-type {
      display: none;
    }
  }

  &.with-description {
    .apply-button:first-of-type:not(:disabled) {
      @media screen and (max-width: $mobileBreakPoint) {
        display: block;
      }
    }
  }

  .main-info, &__extra-info, .feedback-full {
    max-width: 700px;
  }

  &__extra-info {
    background-color: #f8f8f8;
    border-radius: 0.5rem;
    margin: 1rem 0;

    ul {
      margin: 0.6rem 0 0;
    }

    li:not(:first-child) {
      margin-top: 0.4rem;
    }
  }


  &__beskrivelse {
    margin: 0 0 1rem;

    p {
      max-width: 29rem;
      line-height: 1.2rem;
    }

    h2, h3, h4, h5 {
      max-width: 43rem;
    }

    .show-more-button {
      margin-bottom: 1rem;
    }
  }

}

