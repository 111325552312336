@import '../../sass/colors.scss';

.planinfo {

  @media screen and (min-width: 500px){
    margin-left: 1rem;
  }

  &__element {

    margin-bottom: 1.5rem;

    &:first-child {
      margin-top: 1rem;
    }
    &:last-child {
      margin-bottom: 3rem;
    }

    &__merknadtekst {
      margin: 0;
      max-width: 39rem;
      white-space: pre-wrap;
    }

    &__link {
      margin: .5rem 0;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__bekreftelse {
      padding: 1rem 1rem 0 1rem;
      color: lighten($font-color,15);
      .checkbox-container {
        & + .error-message-in-place {
          margin-left: 2.25rem;
          margin-top: 1rem;
        }
        .checkbox-checkmark {
          top: -.1rem;
        }
      }
    }

    .laster-data-container {
      padding: 1.2rem;
      .loader {
        width: 1.5rem;
        height: 1.5rem;
        border-width: .2rem;
      }
    }

    hr {
      margin: 3rem;
      border: lighten($default-border-color,15) .5px solid;
      max-width: 32rem;
    }
    &:last-child {
      hr {
        display: none;
      }
    }

  }

}