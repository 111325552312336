@import "../../sass/colors";

$mobileWidth: 530px;

.dokumentasjon-side {
  .tabs__panel {
    $breakpoint-tabs-1: 700px;
    $breakpoint-tabs-2: 400px;

    margin-bottom: 1rem;
    @media (max-width: $mobileWidth) {
      margin-bottom: 0;
    }

    button {
      font-size: 0.8rem;

      @media (max-width: $breakpoint-tabs-1) {
        font-size: 0.7rem;
        padding: 1rem .5rem 0.9rem .5rem;
      }
      @media (max-width: $breakpoint-tabs-2) {
        text-transform: none;
        padding: 1rem .3rem 0.9rem .3rem;
      }
    }
  }

  .gsk-vedtak {
    li {
      font-size: 1rem;
      padding: 1rem;
    }
  }

  &__arbeidserfaringer {
    ol {
      padding: 0;
    }
  }
}

.dokumenter {

  max-height: 9000px;
  overflow-y: auto;

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    60% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      visibility: collapse;
    }
  }

  ol {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
  }

  $width-merking-kolonne: 4rem;
  $width-filnavn-kolonne: calc(60% - 4rem);

  &__header {

    padding-left: 1rem;
    padding-bottom: 0.4rem;
    padding-top: 1rem;
    border-bottom: 1px solid gainsboro;
    color: $small-text-lightest-gray;
    font-size: 0.85rem;
    font-weight: 700;

    @media (max-width: $mobileWidth) {
      padding-bottom: 0;
      padding-top: 0;
      border-bottom: none;
    }

    & > div {
      display: inline-block;
      @media (max-width: $mobileWidth) {
        display: none;
      }
    }

    &__merking {
      width: $width-merking-kolonne;
    }

    &__filnavn {
      width: $width-filnavn-kolonne;
    }

  }

  .toggler {
    padding: 0 !important;
    border: 0;
    border-bottom: 1px solid $border-table-color;
    margin: .1rem;
    background-color: lighten($button-background-color, 7.5);
    button {
      width: calc(100% - .2rem);
      margin: 0;
      border: 0;
      border-radius: 0;
      font-size: .8rem;
      padding: .5rem 0;
      background-color: lighten($button-background-color, 7.5);
      &:hover {
        background-color: lighten($button-background-color, 5);
      }
    }
  }

  .feedback {
    text-align: left;
  }
  .laster-data-container {
    .loader {
      width: 2.8rem;
      height: 2.8rem;
      border-width: .3rem;
    }
  }

  table {
    .document-heading {
      color: #767676;
      font-size: 0.85rem;
      font-weight: 700;

      th {
        padding-bottom: 10px;
      }

      .filnavn {
        width: 50%;
      }

      .dato {
        width: 40%;
      }

      .action {
        width: 10%;
      }
    }

    .document-row {
      height: 4rem;
      font-size: 16px;
    }

    .deleted {
      height: 0;
      opacity: 0;
      visibility: collapse;
      overflow: hidden;
      border-bottom-width: 0;
      transition: all 0.6s ease-in;
    }
  }

}