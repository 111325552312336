@import '../sass/colors';
@import '../sass/mixins';

.feedback-errors {
  background-color: $feedback-background-color-error;


  .feedback-full {
    h4 {
      margin: 0;
    }
  }

  &__detaljert-info {
    padding: 0 1rem 1rem 2rem;
  }

  .element-beskrivelse {
    margin-right: 1rem;
  }
   li {
     margin-bottom: .5rem;
     line-height: 1.5rem;
   }

  p:first-child {
    margin-top: 0;
  }

  .errors-tilknytninger {
    &__arbeidserfaring {
      span {
        &.label {
          margin: 0 .3rem;
        }
      }

    }
  }

}