@import '../sass/colors.scss';


@media only screen and (min-width: 425px) {
  .Select
  , input[type="text"]
  , input[type="email"]
  , input[type="tel"]
  , input[type="date"]
  {
    width: 100%;
    display: block;
  }
}

input[type="number"]
, input[type="password"]
, input[type="email"]
, input[type="tel"]
, input[type="date"]
, input[type="text"].basic-input
, textarea {

  border-radius: 5px;
  border: 1px solid #707070;
  font-size: 16px;
  padding: 8px;
  max-width: 100%;
  margin: 0 0 15px 0;
  &:focus {
    outline: none;
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px #0067C5;
  }

}
