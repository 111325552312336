@import "../../sass/colors";

$mobileWidth: 530px;

$width-type-kolonne: 9rem;
$width-innhold-kolonne: calc(100% - 22rem);


.inngaende-dokumenter {

  .icon {
    margin-right: 0.5rem;
  }

  ol {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  .type {
    width: $width-type-kolonne;
    display: inline-block;
  }

  .innhold {
    width: $width-innhold-kolonne;
    display: inline-block;
    padding: 2px;
  }

  .dato-mottat {
    display: inline-block;
  }

  &__header {
    padding: 1rem 0 0.4rem 1rem;

    border-bottom: 1px solid gainsboro;
    color: $small-text-lightest-gray;
    font-size: 0.85rem;
    font-weight: 700;

    @media (max-width: $mobileWidth) {
      display: none;
    }

  }

  &__row {

    &:not(:last-child) {
      border-bottom: 1px solid $border-table-color;
    }

    &:hover {
      background-color: $row-hover-background-color;
    }

    .clickable-area {
      display: flex;
      align-items: center;
      height: 4rem;
      padding-left: 0.8rem;
    }

    .type {
      & > span {
        vertical-align: super;
      }
    }

    .innhold {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    @media (max-width: $mobileWidth) {

      .clickable-area {
        display: block;
        height: 6.5rem;
      }


      &:first-child {
        border-top: 1px solid $border-table-color;
      }

      .type, .innhold, .dato-mottat {
        display: block;
        width: auto;
      }

      .dato-mottat {
        padding: .5rem 1rem 1rem 0;
        font-size: .8rem;
      }

      .type {
        padding-top: 1rem;
      }
    }
  }
}


.epost-innhold {

  margin: 0;

  dt {
    text-transform: capitalize;
    color: $small-text-lightest-gray;
    display: inline-block;
    margin: 0.4rem 0 0.1rem 0;
  }
  dd {
    margin: 0 0 0 .25rem;
    display: inline-block;
  }

  .body {
    margin-top: 2rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
    dt {
      display: none;
    }
    dd {
      margin: 0;
    }
  }
}

.sms-innhold {

  &__dato {
    display: inline-block;
    font-size: 0.95rem;
    color: $small-text-lightest-gray;
    margin-bottom: 0.3rem;
  }

  .body {
    margin-top: .2rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
  }
}


