footer {
  color: #505050;
  font-size: 14px;
  width: 100%;
  padding: 30px 0;
  background: #f6f5f4;
  border-top: 1px solid #d2d6dd;
  flex-shrink: 0; //for IE

  .container {
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  ul {
    list-style: none;
    line-height: 22px;
    margin: 0;
    li address {
      font-style: normal;
    }
  }

  .footer-info ul li:first-child {
    font-weight: bold;
    color: #555555;
  }


  .footer-info {
    margin-bottom: 2rem;
    flex: 1 1 350px;
    max-width: 620px;

  }
  .site-map{
    flex-basis: 350px;
    display: flex; //for IE
    flex-direction: column; //for IE


    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 130px;
      flex-basis: 130px; //for IE
    }



    h2 {
      line-height: 22px;
      font-weight: bold;
      padding-left: 40px;
      font-size: 1rem;
      color: #666666;
      margin: 0;
    }
  }


}

