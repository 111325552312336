@import '../sass/colors.scss';

.button-as-link {
  background: none;
  padding: 0;
  cursor: pointer;
  text-transform: none;
  color: $link-color;
  text-decoration: none;
  border-radius: 0;
  font-size: 1rem;
  border-bottom: 1px dotted $link-color;

  &:hover, &:focus {
    background: none;
    border-bottom: 1px solid $link-color;
  }

  .icon {
    color: $link-color;
    margin-right: 0;
    margin-left: 0;
    vertical-align: -.3rem;
  }
}