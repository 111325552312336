@import '../../sass/colors';

.svar-pa-tilbud {
    .feedback-full {
      margin-bottom: 0;
      margin-top: 1rem;
  }

  &__kursnavn {
    font-size: x-large;
    padding-bottom: 1rem;
  }

  &__status, &__frist {
    padding-bottom: 1rem;
  }

  &__label {
    color: $small-text-lightest-gray;
  }

  &__value {
    margin-top: .5rem;
    display: block;
  }

  fieldset {
    margin-left: 0;

    legend {
      margin-bottom: .6rem;
    }

    .radio {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }

}