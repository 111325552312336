@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';
@import '../../sass/less-fun-variables';

$mobileBreakPoint: 755px;

#kursoversikt {

  display: flex;
  flex-wrap: wrap;

  h1 {
    flex: 0 0 100%;
    margin: 1rem 0 !important;
  }

  .filtre {

    text-align: left;
    flex: 0 0 16rem;
    padding-right: 2rem;
    h2 {
      margin: 0 0 1rem 0;
    }
    &__filter {
      overflow: hidden;
      margin: 1rem 0;
      padding: .25rem;
      &:first-child {
        margin: 0;
      }
      input[type='text'] {
        padding: .5rem;
        border-radius: .5rem;
        border: .05rem solid lighten($default-border-color,10);
        &:focus {
          border: .05rem solid transparent;
        }
      }
      legend, label {
        font-size: 1rem;
        &:first-child {
          font-size: .9rem;
          color: $small-text-lightest-gray;
        }
      }
      .checkbox-container {
        &:nth-child(2) {
          margin-top: .75rem;
        }
      }
    }

    &__ekstra {
      @media screen and (max-width: $mobileBreakPoint){
        &.collapsed {
          display: none;
        }
        &.extended {
          top: -1rem;
        }
      }
    }

    &__toggler {
      margin-bottom: 2rem;
      @media screen and (min-width: $mobileBreakPoint) {
        display: none;
      }
    }
  }

  .annen-institusjon {
    margin-left: 1rem;
  }

  .text-fra-institusjon {
    padding: 1rem;
    border: 1px solid lighten($default-border-color,15);
    margin-bottom: 1.5rem;
    border-radius: .5rem;
    background-color: lighten($feedback-background-color-default,3);
    white-space: pre-wrap;
    a {
      color: $navbarLinkColor;
      text-decoration: none;
      border-bottom: 1px dotted $navbarLinkColor;
      cursor: pointer;
    }

  }

}


