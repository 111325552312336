@import '../sass/responsivity';

.dropzone {
  border: 1px dashed #707070;
  border-radius: 1rem;
  text-align: center;
  padding: 0.5rem;

  .icon {
    margin-right: .35rem;
    vertical-align: -.35rem;
  }

  &:hover {
    background-color: #ebf5ff;
    cursor: pointer;
  }

  &:focus {
    border: 1px solid #fff;
  }

  &__text-desktop {
    @media (max-width: $mobileWidth) {
      display: none;
    }
  }

  &__text-mobile {
    @media (min-width: $mobileWidth) {
      display: none;
    }
  }
}