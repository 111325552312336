@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

.ny-bruker {

  .cards-container {
    max-width: 850px;
    align-items: flex-start;

    .flat-card {
      flex: 1 1 250px;

      .content {
        padding: 0 0 1rem 0.5rem;
      }
    }
  }

  .grunnleggende-informasjon, .annet {
    .read-only-field .value {
      font-size: large;
    }

    .read-only-field:not(:last-child) {
      margin-bottom: 1rem;
    }
  }


  form {
    .navn {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;

      &__fornavn {
        margin-right: 20px;
      }

      input {
        max-width: 250px;
        display: block;
      }

    }

    .action-panel .button {
      margin: 20px 0 20px 10px;
    }
  }

  .arbeidsepost {
    margin-top: 1rem;
  }

  .privat-epost {
    margin-top: 1rem;

    .header-icon.icon {
      @include md-icon-size(1.3rem);
    }
  }


  .info-with-button-wrapper {
    white-space: nowrap;
    display: flex;
    align-items: center;

    & > .read-only-field {
      flex-grow: 1;
      min-width: 0;
    }

    & > .read-only-field.text-in-header {
      display: flex;
      flex-direction: column;
    }

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: large;
    }

    .icon {
      height: 1.3rem;
      width: 1.3rem;
    }

  }

}