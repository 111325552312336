@import '../sass/colors';
@import '../sass/mixins';

.info-element {
  .icon {
    @include md-icon-size(1.2rem);
    vertical-align: bottom;
    margin-right: .4rem;
  }

  &__extra-text {
    color: $small-text-lightest-gray;
  }

}