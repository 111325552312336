@import '../../sass/colors';
@import '../../sass/mixins';

$mobileWidth: 365px;

.arbeidserfaring {
  border-radius: .5rem;
  background-color: $arbeidserfaringBgColorInactive;
  margin-bottom: .5rem;
  border-bottom: 1px solid $default-border-color-light;
  display: flex;
  position: relative;

  .button {
    margin-right: 0;
  }

  .arbeidsgiver-og-stillingstittel {
    margin-top: .25rem;
  }

  .periode {
    font-weight: bold;
    span {
      font-size: .95rem;
      &.label {
        margin: 0 .3rem;
      }
    }
  }

  .vedlegg {
    margin-top: .5rem;
  }

  .toggler {
    background-color: $arbeidserfaringBgColorDark;
    min-width: 3rem;
    position: relative;
    .checkbox-checkmark {
      position: absolute;
      top: 1.3rem;
      left: .8rem;
    }
  }

  &__info {
    flex-grow: 1;
    padding: .75rem;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    padding: .75rem .25rem .75rem 0;
  }


  .endre-button {
    margin-left: .25rem;
    .icon {
      @include md-icon-size(1.1rem);
    }
    vertical-align: .2rem;
  }

  &.tidligere-brukt {
    .endre-button {
      @media (max-width: $mobileWidth) {
        position: absolute;
        right: 0;
        top:.25rem;
      }
    }
    .delete-button {
      @media (max-width: $mobileWidth) {
        position: absolute;
        right: 0;
        top: 2.75rem;
      }
    }
    .arbeidserfaring__actions {
      @media (max-width: $mobileWidth) {
        width: 2rem;
      }
    }
  }

  .delete-button {
    .icon {
      @include md-icon-size(1.4rem);
    }
  }

  .fjern-tilknytning-button {
    .icon {
      color: $fjern-tilknytningColor;
      @include md-icon-size(1.2rem);
    }

    &:hover {
      .icon {
        color: $fjern-tilknytningColor-hover;
      }
    }
  }


}


