@import 'less-fun-variables';
@import 'less-fun-tables';
@import 'colors';

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  background: #fff;
  color: #444;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}


div.container {
  position: relative;
  width: $containerWidth;
  margin: 0 auto;
  padding: 0 10px;
  @media screen and (max-width: $containerBreakPoint) {
    width: 100%;
    padding: 0 10px;
  }
}

main#content {
  h1 {
    margin: 20px 10px 10px 10px;
  }
  .cookies {
    h1 {
      margin-left: 0;
    }
    code {
      background-color: #f2f2f2;
    }
  }
  &:focus {
    outline: none;
  }
}

.link {
  color: #0f6a88;
  text-decoration: none;
  border-bottom: 1px dotted $link-color;
  cursor: pointer;
}

.link:hover {
  border-bottom: 1px solid $link-color;
}

.link.ui-state-disabled, .link.ui-state-disabled:hover {
  border-bottom: none;
  color: #444;
}

.link img {
  border: none;
}

.react-select {
  margin: 0 0 15px;
}

.cookies {
  padding-bottom: 30px;
  p {
    max-width: 29em;
  }
}