.main-info {

  $kursinfo-border-color: #cccccc;

  border: 0.05rem solid $kursinfo-border-color;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 1rem 0;

  .info-element {
    flex: 1 1 165px;
    padding: 1rem;
    position: relative;

    text-align: center;
    .info-element__value {
      margin-top: 0.5rem;
    }

    &:not(:last-child):after {
      content: "";
      border-right: 1px solid $kursinfo-border-color;
      position: absolute;
      top: 15px;
      bottom: 15px;
      right: 0;

      @media screen and (max-width: 540px) {
        content: none;
      }
    }

    &:not(:last-child):before {
      content: none;
      @media screen and (max-width: 540px) {
        content: "";
        position: absolute;
        left: 1rem;
        bottom: 0;
        width: calc(100% - 3rem);
        border-bottom: 1px solid $kursinfo-border-color;
      }
    }

    &__extra-text {
      @media screen and (max-width: 540px) {
        display: block;
      }
    }

  }

  @media screen and (max-width: 540px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .info-element {
      flex: 1 1 auto;
      text-align: left;
      width: 100%;

      &__value, &__title {
        display: inline-block;
      }

      &__title {
        width: 60%;
      }

      &__title:after {
        content: ':';
        margin-right: 0.4rem;
      }

    }

  }

}
