$containerBreakPoint: 1200px;
$containerWidth: 990px;
$mobileBreakPoint: 500px;

$navbarTextColor: #e8e8e8;
$navbarWrapperColor: #4c4c4c;
$navbarExtendedBackgroundColor: #fff;
$navbarLinkColor: #0f6a88;
$navbarLinHoverColor: #222;
$navbarLinHoverBackgroundColor: #f4f4f4;
$navbarContentLinkColor: #fff;